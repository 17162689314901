import {
  collection, query, where, orderBy, getFirestore, connectFirestoreEmulator, getDocs, getDoc, doc, setDoc, addDoc, updateDoc, deleteDoc
} from "firebase/firestore";
import firebaseapp from "../../config/firebaseapp";

export class DBStoreService {
  constructor() {
    this.app = firebaseapp;
    this.db = getFirestore(this.app);
    if (process.env.NODE_ENV === 'development') {
      connectFirestoreEmulator(this.db, 'localhost', 8080);
    }
  }

  static instance;

  /**
   * It takes a collection name and an array of where clauses, and returns an array of documents that
   * match the where clauses
   * @param collectionName - The name of the collection you want to query
   * @param whereClauses - An array of arrays. Each array is a where clause.
   * @returns An array of objects.
   */
  async getDocuments(collectionName, whereClauses, orderClauses) {
    const whereConstraints = whereClauses?.map((w) => where(...w)) ?? [];
    // commenting out order clauses because of indices mess
    const orderConstraints = []; //orderClauses?.map((o) => orderBy(...o)) ?? [];
    const q = query(collection(this.db, collectionName), ...whereConstraints, ...orderConstraints)
    const snapshot = await getDocs(q);
    const docs = [];
    snapshot.forEach((doc) => {
      docs.push({ id: doc.id, ...doc.data() });
    })

    return docs;
  }

  /**
   * It takes a collection name, a key to use as the dictionary key, and any number of where clauses,
   * and returns a dictionary of documents from that collection
   * @param collectionName - The name of the collection to query.
   * @param [byKey=id] - The key to use as the key in the dictionary.
   * @param whereClauses - An array of where clauses. Each where clause is an object with the following
   * properties:
   * @returns A dictionary of documents from the collection.
   */
  async getDocumentsDict(collectionName, byKey = 'id', whereClauses) {
    const docs = await this.getDocuments(collectionName, whereClauses);
    return docs.reduce((dict, doc) => ({ ...dict, [doc[byKey]]: doc }), {});
  }

  /**
   * It sets the data of a document in a collection
   * @param collectionName - The name of the collection to set the document in.
   * @param data - The data to set.
   * @param pathSegments - An array of strings that will be used to create a path to the document.
   * @returns A promise that resolves to the data that was set.
   */
  async setDoc(collectionName, data, path) {
    const segments = typeof path === 'string' ? [path] : path;
    return setDoc(doc(this.db, collectionName, ...segments), data);
  }

  /**
   * It takes a collection name and data, and returns a promise that resolves to the id of the newly
   * created document
   * @param collectionName - The name of the collection you want to add the document to.
   * @param data - The data to be added to the collection.
   * @returns The id of the document that was added to the collection.
   */
  async addDoc(collectionName, data) {
    const docRef = await addDoc(collection(this.db, collectionName), data);
    return docRef.id;
  }

  /**
   * It updates a document in a collection
   * @param collectionName - The name of the collection you want to update.
   * @param data - The data to update the document with.
   * @param path - An array of strings that will be used to build the path to the document.
   * @returns A promise that resolves to the result of the update operation.
   */
  async updateDoc(collectionName, path, data) {
    const segments = typeof path === 'string' ? [path] : path;
    const docRef = doc(this.db, collectionName, ...segments);

    return updateDoc(docRef, data);
  }

  /**
   * It deletes a document from a collection
   * @param collectionName - The name of the collection you want to delete a document from.
   * @param pathSegments - An array of strings that represent the path to the document.
   * @returns A promise that resolves when the document is deleted.
   */
  async deleteDoc(collectionName, path) {
    const segments = typeof path === 'string' ? [path] : path;
    const docRef = doc(this.db, collectionName, ...segments);
    return deleteDoc(docRef);
  }

  /**
   * It creates a singleton instance of the DBStoreService class.
   * @returns The instance of the DBStoreService class.
   */
  static getInstance() {
    DBStoreService.instance = DBStoreService.instance ?? new DBStoreService();
    return DBStoreService.instance;
  }

  /**
   * It returns a document from a collection
   * @param collectionName - The name of the collection you want to get the document from.
   * @param pathSegments - An array of strings that represent the path to the document.
   * @returns An object with the id and data of the document.
   */
  async getDocument(collectionName, path) {
    const segments = typeof path === 'string' ? [path] : path;
    const docRef = doc(this.db, collectionName, ...segments);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      return null;
    }
  }
}

export const dbStore = DBStoreService.getInstance();