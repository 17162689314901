import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { logout } from "../services/AuthService";
import "./console-navbar.css";
//import logo from './logo.svg';
import logo from "./sportsmash-logo-128.png";

function ConsoleNavbar() {
  const navigate = useNavigate();
  const auth = useAuth();

  const [user, setUser] = useState({});

  useEffect(() => {
    auth.getSession().then((data) => {
      setUser(data?.auth?.currentUser);
    });
  }, []);

  const handleLogout = async (e) => {
    e.preventDefault();

    await logout();
    navigate("/login");
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark sticky-top p-1 shadow">
      <div className="d-flex flex-grow-1 justify-content-between">
        <a className="navbar-brand d-flex" href="/dashboard">
          <img src={logo} alt="console logo" className="navbar-logo" />
          Sportsmash
        </a>
        <div className="mr-3">
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
        </div>
        <div
          className="collapse navbar-collapse flex-grow-1 text-right"
          id="navbarDropdown"
        >
          <ul className="navbar-nav ms-auto flex-nowrap">
            <li className="nav-item dropdown">
              <button
                className="nav-link dropdown-toggle nav-avatar-dropdown"
                id="dropdownMenu"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {user?.displayName ?? user?.email}
              </button>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownMenu"
              >
                <li>
                  <button className="dropdown-item" onClick={handleLogout}>
                    Logout
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default ConsoleNavbar;
