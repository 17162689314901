import { useNavigate, useParams, Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { Form, Button, FormControl, FormLabel, Spinner } from 'react-bootstrap';
import { dbStore } from "../../services/api/db-store.service";
import formValidation from '../common/validation';
import { CountriesContext } from '../../contexts/CountriesContext';


function Country() {
  const { id } = useParams();
  const [country, setCountry] = useState(null);
  const [originalName, setOriginalName] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { setCountries } = useContext(CountriesContext);

  useEffect(() => {
    setIsLoading(true);
    // load country from api if id is present
    if (id) {
      dbStore.getDocument("countries", id).then((data) => {
        if (!data) {
          navigate("/error-404");
        } else {
          setCountry(data);
          setOriginalName(data.name);
          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(false);
      setCountry({});
    }
  }, [id]);

  //form field validators
  const validators = {
    name: {
      required: {
        value: true,
        message: "Name is required",
      },
    },
    tricode: {
      required: {
        value: true,
        message: "Tricode is required",
      },
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { valid, errors } = formValidation(country, validators);

    if (!valid) {
      setErrors(errors);
      return;
    }

    setErrors({});

    //if no errors
    handleSave();
  };

  const handleSave = async () => {
    setIsLoading(true);

    if (id) {
      await dbStore.updateDoc("countries", id, country);
      setOriginalName(country.name);
    } else {
      const newId = await dbStore.addDoc("countries", country);
      navigate('/dashboard/country/' + newId);
    }

    setCountries();

    setIsLoading(false);
  };

  const title = isLoading ? 'Loading' : (originalName ?? "New Country");

  const FormErrors = () => (
    <div>
      {Object.entries(errors).map(([key, error]) => (
        <div className="text-danger" key={key}>
          {error.message}
        </div>
      ))}
    </div>
  );

  const SubmitButton = () => (
    <Button
      className="btn btn-lg btn-primary"
      type="button"
      disabled={isLoading}
      onClick={handleSubmit}
    >
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        hidden={!isLoading}
      />
      <span className="px-2">Save</span>
    </Button>
  );

  // return country === 'error'
  //   ? <div>This country does not exist.</div>
  //   : country
  //     ? <div>Behold, {country.name ?? 'a country!'}</div>
  //     : <div className="loading">Loading...</div>

  return (
    <main className="container-main">
      <div className="row mb-4">
        <div className="col">
          <Link to="/dashboard/countries">
            {'<'} Back to Countries
          </Link>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">
          <h1>{title}</h1>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 col-md-8">
          {!country ? (
            <div className="loading">Loading...</div>
          ) : (
            <Form noValidate>
            <Form.Group className="form-floating mb-2" controlId="countryName">
              <FormControl
                type="text"
                className="form-control form-input-top"
                isInvalid={errors?.name}
                placeholder="Country Name"
                value={country.name ?? ''}
                onChange={(e) =>
                  setCountry({ ...country, name: e.target.value })
                }
              />
              <FormLabel>Name</FormLabel>
            </Form.Group>

            <Form.Group className="form-floating mb-2" controlId="countryTricode">
              <FormControl
                type="text"
                className="form-control form-input-top"
                isInvalid={errors?.tricode}
                placeholder="Tricode"
                value={country.tricode ?? ''}
                onChange={(e) =>
                  setCountry({ ...country, tricode: e.target.value })
                }
              />
              <FormLabel>Tricode</FormLabel>
            </Form.Group>

            <Form.Group className="form-floating mb-2" controlId="countryFlag">
              <FormControl
                type="text"
                className="form-control form-input-top"
                placeholder="Flag image URL"
                value={country.flag ?? ''}
                onChange={(e) =>
                  setCountry({ ...country, flag: e.target.value })
                }
              />
              <FormLabel>Country Flag URL</FormLabel>
            </Form.Group>
            </Form>
          )}
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 col-md-8">
          <SubmitButton />
          <div className="mt-4">
            <FormErrors />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Country;