function formValidation(entity, validators) {
  if (validators) {
    let valid = true;
    const errs = {};
    for (const [ fieldname, validator ] of Object.entries(validators)) {
      const value = entity[fieldname];
      if (validator?.required?.value && !value) {
        valid = false;
        errs[fieldname] = {
          invalid: true,
          message: validator.required.message,
        };
      }

      const pattern = validator?.pattern;
      if (pattern?.value && !RegExp(pattern.value).test(value)) {
        valid = false;
        errs[fieldname] = { invalid: true, message: pattern.message };
      }

      const custom = validator?.custom;
      if (custom?.isValid && !custom.isValid(value)) {
        valid = false;
        errs[fieldname] = { invalid: true, message: custom.message };
      }
    }
    return { valid, errors: errs };
  } else {
    return { valid: true, errors: {} };
  }
}

export default formValidation;