import { NavLink } from "react-router-dom";
import ENTITIES from "../entities";
import Form from "react-bootstrap/Form";

import "./sidebar.css";

import { useContext } from "react";
import { EventContext, EventsContext } from "../contexts/EventContext";
import { Button } from "react-bootstrap";
import { CountriesContext } from "../contexts/CountriesContext";
import { SeasonContext } from "../contexts/SeasonsContext";

function Sidebar() {
  const { events, setEvents } = useContext(EventsContext);
  const { event, setEvent } = useContext(EventContext);
  const { season, setSeason } = useContext(SeasonContext);
  const { countries } = useContext(CountriesContext);

  const setCurrentEvent = () => {
    const sortedEvents = getSortedEventEntries(events).reverse();
    const currentEvent = sortedEvents.find(([_, e]) => new Date(e.startDate).getTime() < new Date().getTime());
    setEvent(currentEvent?.[1]);
  }

  const getSortedEventEntries = (eventsDict) => {
    return Object.entries(eventsDict)
    .sort(
      ([_, a], [__, b]) =>
        new Date(a.startDate ?? 0).getTime() -
        new Date(b.startDate ?? 0).getTime()
    )
  }

  const getAllYears = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: currentYear - 2023 + 1 }, (_, index) => 2023 + index);
  }

  const GlobalSeasonPicker = () => (
    <>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h5 className="text-muted">Season</h5>
      </div>

      <Form.Group>
        <Form.Select
          id="seasonSelect"
          defaultValue={season}
          onChange={(e) => {
            const newSeason = Number(e.target.value);
            setSeason(isNaN(newSeason) ? null : newSeason);
          }}
        >
          <option>Select a year</option>
          {getAllYears()
            .map((y, i) => (
              <option key={"year_" + i} value={y}>
                {y}
              </option>
            ))}
        </Form.Select>
      </Form.Group>
    </>
  );

  const GlobalEventPicker = () => (
    <>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h5 className="text-muted">Event</h5>
        <Button variant="outline-secondary" size="sm" onClick={() => setCurrentEvent(null)}>
          <i className="bi bi-calendar"></i> Current
        </Button>
      </div>

      <Form.Group>
        <Form.Select
          id="eventSelect"
          defaultValue={event?.id}
          onChange={(e) => {
            setEvent(events[e.target.value]);
          }}
        >
          <option>Select an Event</option>
          {getSortedEventEntries(events)
            .map(([i, event], idx) => (
              <option key={"event_" + i} value={event.id}>
                {idx + 1}. {event.name}
              </option>
            ))}
        </Form.Select>
      </Form.Group>
    </>
  );

  return (
    <>
      <div className="position-sticky">
        <ul className="nav flex-column">
          <li className="nav-item mb-2">
            <GlobalSeasonPicker />
          </li>

          <li className="nav-item mb-2">
            <GlobalEventPicker />
          </li>
          
          <li className="nav-item d-flex justify-content-between align-items-center">
            {event && <img className="event-flag"
              src={countries?.find((c) => c.tricode === event.country)?.flag} alt={event.name} />}
            
            <NavLink className="nav-link" to="/dashboard/scoring">
              {event?.status !== 'completed'
                ? <span><i className="bi bi-calculator" /> Scoring</span>
                : <span><i className="bi bi-clipboard-data" /> Results</span>}
            </NavLink>
          </li>

          <li><hr /></li>

          <li>
            <h5 className="text-muted">Data management</h5>
          </li>
          {ENTITIES.map((item, i) => (
            <li key={i} className="nav-item">
              <NavLink className="nav-link" end to={item.path}>
                <i className={`bi ${item.icon} pe-2`} />
                {item.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
