import { useContext, useEffect, useState, Fragment } from "react";
import { useParams, useNavigate, Link, NavLink } from "react-router-dom";
import { dbStore } from "../../services/api/db-store.service";
import { EventContext } from "../../contexts/EventContext";
import {
  Button,
  Form,
  FormControl,
  FormLabel,
  FormSelect,
  Spinner,
} from "react-bootstrap";
import formValidation from "../common/validation";
import { getLeaderboard, getUserPicksResults } from "../../services/Scoring";
import ImageUploader from "../common/ImageUploader";
import { uploadImageToFirestore } from "../../services/api/storage.service";
import { functions } from "../../services/api/functions.service";
import { SeasonContext } from "../../contexts/SeasonsContext";

function User() {
  const { id } = useParams();

  const [user, setUser] = useState(null);
  const [originalName, setOriginalName] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [leagueId, setLeagueId] = useState(undefined);

  const [events, setEvents] = useState(null);
  const [eventsResults, setEventsResults] = useState(null);
  const [leagues, setLeagues] = useState(null);
  const [userLeagues, setUserLeagues] = useState([]);
  const [allPicks, setAllPicks] = useState(null);
  const [leaguePicks, setLeaguePicks] = useState(null);
  const [userPicks, setUserPicks] = useState(null);
  
  const [userEventsScores, setUserEventsScores] = useState(null);
  const [totalScore, setTotalScore] = useState(null);

  const [loadingScores, setLoadingScores] = useState(true);

  const navigate = useNavigate();

  const { event: globalEvent } = useContext(EventContext);

  const { season, setSeason } = useContext(SeasonContext);

  useEffect(() => {
    setIsLoading(true);
    // load user from api if id is present
    if (id) {
      dbStore.getDocument("users", id).then((data) => {
        if (!data) {
          navigate("/error-404");
        } else {
          setUser(data);
          setOriginalName(data.name);
          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(false);
      setUser({});
    }
  }, [id]);

  useEffect(() => {
    const startDate = new Date(`${season}/01/01`);
    const endDate = new Date(`${season}/12/31`);
    dbStore.getDocuments('events', [['startDate', '>=', startDate], ['startDate', '<=', endDate]])
      .then((events) => {
        setEvents(events.sort((a, b) => 
          new Date(a.startDate ?? 0).getTime() -
          new Date(b.startDate ?? 0).getTime()))
      });

    dbStore.getDocuments('picks')
      .then((picks) => {
        if (!picks) {
          setAllPicks([]);
          setUserPicks([]);
          return;
        }
        setAllPicks(picks);
      });

    dbStore.getDocuments('eventResults')
      .then((eventsResults) => {
        setEventsResults(eventsResults);
      });

    dbStore.getDocuments('leagues')
      .then((leagues) => {
        setLeagues(leagues);
      });
  }, [season]);

  // Whenever leagues, or picks change, filter the relevant leagues and set them to the state
  useEffect(() => {
    if (!leagues || !allPicks) { return; }

    const userLeagues = leagues.filter((l) => allPicks.some((p) => p.userid === id && p.leagueId === l.id));
    setUserLeagues(userLeagues);
    if (userLeagues.length === 1) {
      setLeagueId(userLeagues[0].id);
    }
    setLoadingScores(false);
  }, [leagues, allPicks]);

  useEffect(() => {
    if (!leagueId || !allPicks) {
      setUserPicks(null);
      setLeaguePicks(null);
      return;
    }
    const leaguePicks = allPicks.filter((p) => p.leagueId === leagueId);
    setLeaguePicks(leaguePicks);
    const userPicks = leaguePicks.filter((p) => p.userid === id);
    setUserPicks(userPicks);
  }, [leagueId, allPicks]);


  useEffect(() => {

    if (events && eventsResults && leagues && userLeagues && leaguePicks && userPicks && leagueId) {
      functions.getUserScores(id, leagueId)
        .then((scores) => { console.log(scores)})
        .catch((error) => { console.error(error)}); 
      const userEventsScores = [];
      for (const event of events) {
        const eventName = event.name;
        const eventResults = eventsResults.find((r) => r.id === event.id);
        const eventPicks = leaguePicks.filter((p) => p.eventId === event.id)
        const leaderboard = getLeaderboard(eventResults, eventPicks);

        const userEventPicks = userPicks.filter((p) => p.eventId === event.id);
        const picks = eventResults ? getUserPicksResults(userEventPicks, eventResults, leagueId) : [];
        let rank = leaderboard?.findIndex((s) => s.userId === id) + 1;
        if (isNaN(rank)) { rank = 0; }
        const totalScore = leaderboard?.find((l) => l.userId === id)?.score;
        userEventsScores.push({
          eventName,
          leaderboard,
          rank,
          totalScore,
          picks,
        });
      }

      setUserEventsScores(userEventsScores);
      setTotalScore(userEventsScores.reduce((acc, score) => acc + (score.totalScore ?? 0), 0));
      

    } else {
      setUserEventsScores(null);
    }
  }, [events, eventsResults, leagues, userLeagues, leaguePicks, userPicks]);

  //form field validators
  const validators = {
    name: {
      required: {
        value: true,
        message: "Name is required",
      },
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { valid, errors } = formValidation(user, validators);

    if (!valid) {
      setErrors(errors);
      return;
    }

    setErrors({});

    //if no errors
    handleSave();
  };

  const handleSave = async () => {
    setIsLoading(true);

    if (id) {
      await dbStore.updateDoc("users", id, user);
    } else {
      await dbStore.addDoc("users", user);
    }

    setIsLoading(false);
    navigate("/dashboard/users");
  };

  const title = isLoading ? "Loading" : originalName ?? "New User";

  const FormErrors = () => (
    <div>
      {Object.keys(errors).map((key) => (
        <div className="text-danger" key={key}>
          {errors[key]}
        </div>
      ))}
    </div>
  );

  const SubmitButton = () => (
    <Button
      className="btn btn-lg btn-primary"
      type="button"
      disabled={isLoading}
      onClick={handleSubmit}
    >
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        hidden={!isLoading}
      />
      <span className="px-2">Save</span>
    </Button>
  );

  // return user === 'error'
  //   ? <div>This user does not exist.</div>
  //   : user
  //     ? <div>Behold, {user.name ?? 'a user!'}</div>
  //     : <div className="loading">Loading...</div>

  const formatScore = (score) => {
    return Math.floor(Number(score));
  }

  const expandScore = (index) => {
    return () => {
      const newScores = userEventsScores.map((s, i) => ({
        ...s,
        expanded: i !== index ? false : (s.expanded ? false : true),
      }));
      setUserEventsScores(newScores);
    };
  }

  const handleImageUpload = async (image) => {
    const url = await uploadImageToFirestore(image, 'user-profile-pics');
    setUser({ ...user, profilePic: url });
  }

  return (
    <main className="container-main">
      <div className="row mb-4">
        <div className="col">
          <Link to="/dashboard/users">{"<"} Back to Users</Link>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">
          <h1>{title}</h1>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-7">
          {!user ? (
            <div className="loading">Loading...</div>
          ) : (
            <Form noValidate>
              <div className="row">
                <div className="col-md-6">
                  <div className="card mb-4">
                    <div className="card-body my-4 text-center">
                      <ImageUploader imageUrl={user.profilePic} onUpload={handleImageUpload} />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <Form.Group
                        className="form-floating"
                        controlId="firstName"
                      >
                        <FormControl
                          type="text"
                          className="form-control form-input-top"
                          isInvalid={errors?.firstName}
                          placeholder="First Name"
                          value={user.firstName}
                          onChange={(e) =>
                            setUser({ ...user, firstName: e.target.value })
                          }
                        />
                        <FormLabel>First Name</FormLabel>
                      </Form.Group>
                      <FormErrors />
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        className="form-floating"
                        controlId="lastName"
                      >
                        <FormControl
                          type="text"
                          className="form-control form-input-top"
                          isInvalid={errors?.lastName}
                          placeholder="Last Name"
                          value={user.lastName}
                          onChange={(e) =>
                            setUser({ ...user, lastName: e.target.value })
                          }
                        />
                        <FormLabel>Last Name</FormLabel>
                      </Form.Group>
                      <FormErrors />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="form-floating" controlId="email">
                        <FormControl
                          type="text"
                          className="form-control form-input-top"
                          isInvalid={errors?.email}
                          placeholder="Email"
                          value={user.email}
                          onChange={(e) =>
                            setUser({ ...user, email: e.target.value })
                          }
                        />
                        <FormLabel>Email</FormLabel>
                      </Form.Group>
                      <FormErrors />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId="status">
                    <FormSelect
                      className="form-control mb-3"
                      value={user.status}
                      onChange={(e) =>
                        setUser({ ...user, status: e.target.value })
                      }
                    >
                      <option value="">Status</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </FormSelect>
                  </Form.Group>
                  <FormErrors />
                  <Form.Group controlId="role">
                    <FormSelect
                      className="form-control mb-3"
                      value={user.role}
                      onChange={(e) =>
                        setUser({ ...user, role: e.target.value })
                      }
                    >
                      <option value="">Role</option>
                      <option value="Active">Admin</option>
                      <option value="Inactive">User</option>
                    </FormSelect>
                  </Form.Group>
                  <FormErrors />
                  <Form.Group className="form-floating" controlId="startDate">
                    <FormControl
                      type="text"
                      className="form-control form-input-top mb-3"
                      isInvalid={errors?.startDate}
                      placeholder="Member Since"
                      value={user.startDate}
                      onChange={(e) =>
                        setUser({ ...user, startDate: e.target.value })
                      }
                    />
                    <FormLabel>Member Since</FormLabel>
                  </Form.Group>
                  <FormErrors />
                  <Form.Group controlId="league">
                    <FormSelect
                      className="form-control mb-3"
                      value={user.league}
                      onChange={(e) =>
                        setUser({ ...user, league: e.target.value })
                      }
                    >
                      <option value="">Select League</option>
                    </FormSelect>
                  </Form.Group>
                  <FormErrors />
                </div>
              </div>
            </Form>
          )}

          {globalEvent
          ? <div className="row mt-4 mb-4">
            <div className="col">
              <NavLink to={`/mypicks/${id}/${season}/${globalEvent.id}`} target="_blank">User's picks for current event</NavLink>
            </div>
          </div>
          : <div className="row mb-4">
            <div className="col">
              <NavLink to={`/mypicks/${id}/${season}`} target="_blank">User's picks</NavLink>
            </div>
          </div>
          }
          <div className="row mb-4">
            <div className="col">
              <NavLink to={`/top5/${id}`} target="_blank">User's top 5 picks</NavLink>
            </div>
          </div>
        </div>

        <div className="col-md-5">
          <h2>User Scores</h2>

          {userLeagues?.length > 1 ? <>
          <Form.Group controlId="league">
            <FormSelect
              className="form-control mb-3"
              value={leagueId}
              onChange={(e) =>
                setLeagueId(e.target.value)
              }
            >
              <option value="">Select League</option>
              {userLeagues.map((l) => (
                <option key={l.id} value={l.id}>{l.name}</option>
              ))}
            </FormSelect>
          </Form.Group>
          </>
          : <p>{leagues?.find((l) => l.id === leagueId)?.name}</p>}
          {userEventsScores
          ? <>
          <table className="table">
            <thead>
              <tr>
                <th>Total Score</th>
                <th>{formatScore(totalScore)}</th>
              </tr>
            </thead>

            <tbody>
              {userEventsScores.map((score, i) => (<Fragment key={'userEventScore-' + i}>
                <tr className="clickable-row" onClick={expandScore(i)}>
                  <th>{i + 1}. {score.eventName}</th>
                  <th>{formatScore(score.totalScore ?? '')}</th>
                </tr>

                {score.expanded && score.picks?.results ? <tr><th className="ps-3">
                  Final Results:
                </th><th></th></tr> : ''}
                {score.expanded && score.picks?.results?.map((pick, j) => (<tr key={'pick-final-' + i + '-' + j}>
                  <td className="ps-4">{pick.firstName} {pick.lastName}</td>
                  <td>{formatScore(pick.fantasyPoints)}</td>
                </tr>))}

                {score.expanded && score.picks?.active ? <tr><th className="ps-3">
                  Active Surfers:
                </th><th></th></tr> : ''}
                {score.expanded && score.picks?.active?.map((pick, j) => (<tr key={'pick-active-' + i + '-' + j}>
                  <td className="ps-4">{pick.firstName} {pick.lastName}</td>
                  <td>{formatScore(pick.fantasyPoints)}</td>
                </tr>))}

                {score.expanded && score.picks?.eliminated ? <tr><th className="ps-3">
                  Eliminated Surfers:
                </th><th></th></tr> : ''}
                {score.expanded && score.picks?.eliminated?.map((pick, j) => (<tr key={'pick-eliminated-' + i + '-' + j}>
                  <td className="ps-4">{pick.firstName} {pick.lastName}</td>
                  <td>{formatScore(pick.fantasyPoints)}</td>
                </tr>))}
              </Fragment>))}
            </tbody>
          </table>
          </>
          : <>
          <div className="p-4 text-center my-4">
            {loadingScores && <Spinner animation="border" />}
            {!loadingScores && !leagueId && <p>Select a league to view scores</p>}
            {!loadingScores && leagueId && (userEventsScores?.length ?? 0) <= 1 && <p>No scores found for this league</p>}
          </div>
          </>
          }
        </div>
      </div>


      <div className="row mb-4">
        <div className="col-lg-6 col-md-8">
          <SubmitButton />
        </div>
      </div>
    </main>
  );
}

export default User;
