
/**
 * Calculates the leaderboard for an event based on the event results and picks.
 *
 * @param {object} eventResults - The event results.
 * @param {object[]} eventPicks - The event picks.
 * @returns {object[]} The event leaderboard.
 * @returns {string} eventLeaderboard[].userId - The user ID.
 * @returns {number} eventLeaderboard[].score - The score calculated for the user's picks.
 */
export function getLeaderboard(eventResults, eventPicks) {
  if (!eventResults || !eventPicks) { return null; }

  const picksDict = eventPicks.reduce((dict, p) => {
    dict[p.userid] = dict[p.userid] || [];
    dict[p.userid].push(p);
    return dict;
  }, {});

  const eventLeaderboard = Object.entries(picksDict)
    .map(([userId, picks]) => ({
      userId,
      score: picks.reduce((score, p) => {
        let surferScore = eventResults.scores?.[p.surferId];
        if (isNaN(surferScore?.fantasyPoints)) { surferScore = 0; }
        // return score + surferScore.eliminated || surferScore.wonEvent ? surferScore.fantasyPoints : 0;
        return score + (surferScore?.fantasyPoints ?? 0);
      }, 0)
    }))
    .sort((a, b) => b.score - a.score);

  return eventLeaderboard;
};



/**
 * Retrieves the results of user picks for a specific league based on event results.
 *
 * @param {object[]} userPicks - The user's picks.
 * @param {object} eventResults - The event results.
 * @param {string} leagueId - The ID of the league.
 * @returns {object} The sorted user picks results.
 * @returns {object[]} sortedPicks.results - The sorted results of the user's picks for a completed event.
 * @returns {object[]} sortedPicks.active - The sorted active picks of the user for an ongoing event.
 * @returns {object[]} sortedPicks.eliminated - The sorted eliminated picks of the user for an ongoing event.
 */
export function getUserPicksResults(userPicks, eventResults, leagueId) {
  const allPicks = userPicks
    .filter((p) => p.leagueId === leagueId)
    .map((p) => ({
      box: p.box,
      gender: p.pickGender,
      ...(eventResults.scores?.[p.surferId] ?? {})
    }));

  const sortedPicks = {};
  const sortFnEnded = (a, b) => b.fantasyPoints - a.fantasyPoints;
  const sortFnLive = (a, b) => {
    if (a.gender === b.gender) {
      if (a.lastRound === b.lastRound) {
        if (a.lastHeatNumber === b.lastHeatNumber) {
          return b.avgHeatScore - a.avgHeatScore;
        } else {
          return a.lastHeatNumber - b.lastHeatNumber;
        }
      } else {
        return a.lastRound - b.lastRound;
      }
    } else {
      return a.gender === 'M' ? -1 : 1;
    }
  };

  if (eventResults.status === 'completed') {
    sortedPicks.results = allPicks.sort(sortFnEnded);
  } else {
    sortedPicks.active = allPicks
      .filter((p) => !p.eliminated)
      .sort(sortFnLive);
    sortedPicks.eliminated = allPicks
      .filter((p) => p.eliminated)
      .sort(sortFnEnded);
  }

  return sortedPicks;
}