import { useState } from 'react';

import {
  createHashRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.esm.min';
import "react-datepicker/dist/react-datepicker.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';

import '../assets/css/style.css';
import '../assets/css/fonts.css';

import MainLayout from '../layouts/MainLayout';
import DashboardLayout from '../layouts/DashboardLayout';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import NotFound from '../pages/NotFound';
import Dashboard from '../pages/Dashboard';
import AuthProvider from '../hooks/AuthProvider';
import EntityListScreen from '../components/common/EntityListScreen';
import MyPicks from '../components/MyPicks';
import Top5 from '../components/Top5';
import Scores from '../components/edit-screens/Scores';

import ENTITIES from '../entities';

import { EventContext, EventsContext } from '../contexts/EventContext';
import { CountriesContext } from '../contexts/CountriesContext';

import { useEffect } from "react";
import { dbStore } from '../services/api/db-store.service';
import { SeasonContext } from '../contexts/SeasonsContext';
import { Timestamp } from 'firebase/firestore';

const router = createHashRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'logout',
        element: <Logout />,
      },
      {
        path: '',
        element: <Navigate to='/dashboard' />,
      },
      {
        path: '*',
        element: <NotFound />,
      }
    ]
  },
  {
    path: 'mypicks/:userId/:season/:eventId/:leagueId',
    element: <MyPicks />,
  },
  {
    path: 'mypicks/:userId/:season/:eventId',
    element: <MyPicks />,
  },
  {
    path: 'mypicks/:userId/:season',
    element: <MyPicks />,
  },
  {
    path: 'top5/:userId',
    element: <Top5 />,
  },
  {
    path: 'dashboard',
    // index: true,
    element: <DashboardLayout />,
    children: [
      ...ENTITIES.map((entity) => ({
        path: entity.path,
        element: <EntityListScreen
          key={entity.path}
          title={entity.title}
          fields={entity.fields}
          filters={entity.filters}
          actions={entity.actions}
          entityNamePlural={entity.entityNamePlural}
          entityNameSingular={entity.entityNameSingular}
          showIndices={entity.showIndices}
          getItems={entity.getItems}  
          deleteItem={entity.deleteItem}
          editScreenName={'/dashboard/' + entity.entityNameSingular}
        />
      })),
      ...ENTITIES.map((entity) => ({
        path: entity.entityNameSingular,
        element: <entity.editScreen key={entity.entityNameSingular}></entity.editScreen>
      })),
      ...ENTITIES.map((entity) => ({
        path: `${entity.entityNameSingular}/:id`,
        element: <entity.editScreen key={entity.entityNameSingular + '/id'}></entity.editScreen>
      })),
      {
        path: 'scoring',
        element: <Navigate to="/dashboard/scoring/male" />,

      },
      {
        path: 'scoring/:gender',
        element: <Scores />,
      }
    ],
  }
]);

function App() {
  const [event, setEvent] = useState(null);
  const [events, setEvents] = useState({});
  const [season, setSeason] = useState(new Date().getFullYear());
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    let activeEvents = localStorage.getItem('sm_active_event');
    if (activeEvents && activeEvents !== 'undefined') {
      try {
        activeEvents = JSON.parse(activeEvents);
        setEvent(activeEvents[season] ?? null);
      } catch (error) {
        console.log(error)
        // do nothing
      }
    } else {
      localStorage.removeItem('sm_active_event');
    }
    const startDate = new Date(`${season}/01/01`);
    const endDate = new Date(`${season}/12/31`);
    dbStore.getDocumentsDict('events', 'id', [['startDate', '>=', startDate], ['startDate', '<=', endDate]])
      .then((eventsDict) => {
        for (const [_, event] of Object.entries(eventsDict)) {
          if (event.startDate instanceof Timestamp) { event.startDate = event.startDate.toDate(); }
          if (event.endDate instanceof Timestamp) { event.endDate = event.endDate.toDate(); }
        }
        setEvents(eventsDict);
        if(eventsDict[activeEvents[season]?.id]) {
          setAndPersistEvent(eventsDict[activeEvents[season].id]);
        }
      });

    dbStore.getDocuments('countries')
      .then((countries) => {
        setCountries(countries.sort((a, b) => a.name?.localeCompare(b.name)));
      });
  }, [season]);

  const setAndPersistEvent = (event) => {
    setEvent(event);
    let activeEvents = localStorage.getItem('sm_active_event');
    if (activeEvents) {
      try {
        activeEvents = JSON.parse(activeEvents);
        activeEvents[season] = event;
        localStorage.setItem('sm_active_event', JSON.stringify(activeEvents));
      } catch (error) {
        // do nothing
      }
    } else {
      localStorage.setItem('sm_active_event', JSON.stringify({ [season]: event }));
    }
  }

  const setOrRefreshCountries = (countries) => {
    if (!countries) {
      dbStore.getDocuments('countries')
        .then((countries) => {
          setCountries(countries.sort((a, b) => a.name?.localeCompare(b.name)));
        });
    } else {
      setCountries(countries);
    }
  }

  const setOrRefreshEvents = (events) => {
    if (!events) {
      const startDate = new Date(`${season}/01/01`);
      const endDate = new Date(`${season}/12/31`);
      dbStore.getDocumentsDict('events', 'id', [['startDate', '>=', startDate], ['startDate', '<=', endDate]])
        .then((eventsDict) => {
          for (const [_, event] of Object.entries(eventsDict)) {
            if (event.startDate instanceof Timestamp) { event.startDate = event.startDate.toDate(); }
            if (event.endDate instanceof Timestamp) { event.endDate = event.endDate.toDate(); }
          }
          setEvents(eventsDict);
        });
    } else {
      setEvents(events);
    }
  }

  return (
    <AuthProvider>
      <SeasonContext.Provider value={{ season, setSeason }}>
        <EventsContext.Provider value={{ events, setEvents: setOrRefreshEvents} } >
          <EventContext.Provider value={{ event, setEvent: setAndPersistEvent }}>
            <CountriesContext.Provider value={{ countries, setCountries: setOrRefreshCountries }}>
              <RouterProvider router={router} /> 
            </CountriesContext.Provider>
          </EventContext.Provider>
        </EventsContext.Provider>
      </SeasonContext.Provider>
    </AuthProvider>
  );
}

export default App;