import Dropdown from "react-bootstrap/Dropdown";

import "./picker-list-item.css";

export default function PickerListItem({
  item, // should receive an object
  label,
  index,
  onPress,
  onDelete,
  disabled,
  fields,
  actions,
}) {
  const deleteBtnTpl = onDelete ? (
    <button className="btn btn-danger" onClick={onDelete} disabled={disabled}>
      Delete
    </button>
  ) : null;

  const handleEdit = (e) => {
    e.preventDefault();
    if (!disabled) {
      onPress?.(e);
    }
  };
  const handleDelete = (e) => {
    e.preventDefault();
    if (!disabled) {
      onDelete?.(e);
    }
  };
  const getActionHandler = (action) => async (e) => {
    e.preventDefault();
    if (!disabled && typeof action.action === "function") {
      const result = await action.action(item);
      if (typeof action.callback === "function") {
        action.callback(result);
      }
    }
  };
  return (
    <tr>
      {index !== undefined && <td>{index}</td>}

      {fields?.map(({ value, type, noLink }, idx) => (
        <td key={idx}>
          {noLink ? (
            <>
            {type === 'image' && <img className="table-img" src={typeof value === "function" ? value(item) : item[value]} alt={item.name} />}
            {type !== 'image' && <span className="text">
              {typeof value === "function" ? value(item) : item[value]}
            </span>}
            </>
          ) : (
            <a href="#" onClick={handleEdit}>
              {type === 'image' && <img className="table-img" src={typeof value === "function" ? value(item) : item[value]} alt={item.name} />}
              {type !== 'image' && <span className="text">
                {typeof value === "function" ? value(item) : item[value]}
              </span>}
            </a>
          )}
        </td>
      ))}

      <td className="text-end">
        <Dropdown>
          <Dropdown.Toggle
            variant="primary"
            id={"item-menu-" + (item.id ?? item.name ?? label)}
          >
            <i className={`bi bi-three-dots pe-2`} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {actions?.map(
              (action, idx) =>
                typeof action.show === "function" &&
                action.show(item) && (
                  <Dropdown.Item
                    key={action.label + idx}
                    onClick={getActionHandler(action)}
                  >
                    {action?.label}
                  </Dropdown.Item>
                )
            )}
            {onPress && (
              <Dropdown.Item onClick={handleEdit}>Edit</Dropdown.Item>
            )}
            {onDelete && (
              <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
}
