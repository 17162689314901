import { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormLabel,
  Row,
  Spinner,
} from "react-bootstrap";
import { browserLocalPersistence, getAuth, setPersistence, signInWithEmailAndPassword } from "firebase/auth";
import Toast from "../components/Toast";
import "./auth.css";
import firebaseapp from "../config/firebaseapp";

function redirectPath(search) {
  const match = search.match(/redirect=(.*)/);
  const redirect = match?.[1];
  return redirect ? decodeURIComponent(redirect) : "/dashboard";
}

export const Login = () => {
  const title = "Login";
  //variables
  const [data, setData] = useState({emailid:'',password:''});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const alertOpts = useRef({ isShow: false, message: "" });

  //form field validators
  const validators = {
    emailid: {
      required: {
        value: true,
        message: "emailid is required",
      },
    },
    password: {
      required: {
        value: true,
        message: "password is required",
      },
    },
  };

  //form functions
  const handleDismiss = () => {
    alertOpts.current.isShow = false;
  };
  const handleLogin = async (e, data) => {
    // eslint-disable-next-line no-console
    try {
      setIsLoading(true);
      const auth = getAuth(firebaseapp);
      await setPersistence(auth, browserLocalPersistence);
      const userInstance = await signInWithEmailAndPassword(
        auth,
        data.emailid,
        data.password
      );
      setIsLoading(false);
      navigate(redirectPath(search));
    } catch (err) {
      alertOpts.current = { isShow: true, message: err.message };
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validators) {
      let valid = true;
      const errs = {};
      for (const fieldname in validators) {
        if (Object.hasOwnProperty.call(validators, fieldname)) {
          const value = data[fieldname];
          const validator = validators[fieldname];
          if (validator?.required?.value && !value) {
            valid = false;
            errs[fieldname] = validator.required.message;
          }

          const pattern = validator?.pattern;
          if (pattern?.value && !RegExp(pattern.value).test(value)) {
            valid = false;
            errs[fieldname] = pattern.message;
          }

          const custom = validator?.custom;
          if (custom?.isValid && !custom.isValid(value)) {
            valid = false;
            errs[fieldname] = custom.message;
          }
        }
      }

      if (!valid) {
        setErrors(errs);
        return;
      }
    }

    setErrors({});

    //if no errors
    handleLogin(e, data);
  };

  //ui components
  const LoginText = () => <h4 className="mb-3 fw-normal">Login</h4>;
  const LockIcon = () => <i className="bi bi-file-lock-fill auth-icon my-4" />;
  // const EmailInputField = () => (

  const FormErrors = () => (
    <div>
      {Object.keys(errors).map((key) => (
        <div className="text-danger" key={key}>
          {errors[key]}
        </div>
      ))}
    </div>
  );
  const SubmitButton = () => (
    <Button
      className="w-100 btn btn-lg btn-primary"
      type="button"
      disabled={isLoading}
      onClick={handleSubmit}
    >
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        hidden={!isLoading}
      />
      <span className="px-2">Log in</span>
    </Button>
  );

  //render
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="container-auth text-center">
        <Form noValidate>
          <LoginText />
          <LockIcon />
          <Form.Group className="form-floating" controlId="inputEmailid">
            <FormControl
              type="text"
              className="form-control form-input-top"
              isInvalid={errors?.emailid}
              placeholder="Enter Emailid"
              value={data.emailid}
              onChange={(e) => setData({ ...data, emailid: e.target.value })}
            />
            <FormLabel>Username</FormLabel>
          </Form.Group>
          <Form.Group className="form-floating" controlId="inputPassword">
            <FormControl
              type="password"
              className="form-control form-input-bottom"
              isInvalid={errors?.password}
              placeholder="Password"
              onChange={(e) => setData({ ...data, password: e.target.value })}
            />
            <FormLabel>Password</FormLabel>
          </Form.Group>
          <FormErrors />
          <SubmitButton />
        </Form>
      </main>
      <Toast
        show={alertOpts.current.isShow}
        variant="failure"
        message={alertOpts.current.message}
        onDismiss={handleDismiss}
      />
    </>
  );
};

export default Login;
