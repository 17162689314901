import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { dbStore } from '../../services/api/db-store.service';
import {
  Button,
  Form,
  FormControl,
  FormLabel,
  FormSelect,
  Spinner,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import formValidation from '../common/validation';
import ImageUploader from '../common/ImageUploader';
import { uploadImageToFirestore } from '../../services/api/storage.service';
import { EventsContext } from '../../contexts/EventContext';
import { Timestamp } from 'firebase/firestore';

function League() {
  const { id } = useParams();
  const [league, setLeague] = useState(null);
  const [users, setUsers] = useState(null);
  const [allPicks, setAllPicks] = useState(null);
  const [originalName, setOriginalName] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);


  const { events: globalEvents, setEvents: setGlobalEvents } = useContext(EventsContext);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    // load league from api if id is present
    if (id) {
      dbStore.getDocument("leagues", id).then((data) => {
        if (!data) {
          navigate("/error-404");
        } else {
          data.startDate = data.startDate instanceof Timestamp ? data.startDate.toDate() : null; // convert Firestore timestamp to date
          setLeague(data);
          setOriginalName(data.name);
          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(false);
      setLeague({});
    }
  }, [id]);

  useEffect(() => {
    !users && dbStore.getDocumentsDict('users')
      .then((usersDict) => {
        setUsers(usersDict ?? {});
      })
      .catch((error) => {
        console.error(error);
        setUsers([]);
      });

    !allPicks && dbStore.getDocuments('picks')
      .then((picks) => {
        setAllPicks(picks);
      })
      .catch((error) => {
        console.error(error);
        setAllPicks([]);
      });

    if (league && !league.users && users && allPicks && globalEvents) {
      const updatedLeague = {
        ...league,
        users: Object.keys(users)
          .filter((userId) => {
            return allPicks.some((pick) => {
              return pick.userid === userId && pick.leagueId === league.id
                && Object.values(globalEvents).some((event) => event.id === pick.eventId);
            });
          })
          .map((userId) => ({
              id: userId ?? null,
              name: users[userId].name ?? null,
              email: users[userId].email ?? null,
          })),
      };
      setLeague(updatedLeague);

      // TODO: Users table for this league
    }
  }, [league, users, allPicks, globalEvents]);

  //form field validators
  const validators = {
    name: {
      required: {
        value: true,
      },
    },
    status: {
      required: {
        value: true,
      },
    },
    password: {
      required: {
        value: true,
      },
    },
    startDate: {
      required: {
        value: true,
      },
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { valid, errors } = formValidation(league, validators);

    if (!valid) {
      setErrors(errors);
      return;
    }

    setErrors({});

    //if no errors
    handleSave();
  };

  const handleSave = async () => {
    setIsLoading(true);

    if (id) {
      await dbStore.updateDoc("leagues", id, league);
      setOriginalName(league.name);
    } else {
      const newId = await dbStore.addDoc("leagues", league);
      navigate('/dashboard/league/' + newId);
    }

    setIsLoading(false);
  };

  const title = isLoading ? 'Loading' : (originalName ?? "New League");

  const FormErrors = () => (
    <div>
      {Object.entries(errors).map(([key, error]) => (
        <div className="text-danger" key={key}>
          {error.message}
        </div>
      ))}
    </div>
  );

  const SubmitButton = () => (
    <Button
      className="btn btn-lg btn-primary"
      type="button"
      disabled={isLoading}
      onClick={handleSubmit}
    >
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        hidden={!isLoading}
      />
      <span className="px-2">Save</span>
    </Button>
  );

  const handleImageUpload = async (image) => {
    const url = await uploadImageToFirestore(image, 'league-pictures');
    setLeague({ ...league, leaguePicture: url });
  }

  // return league === 'error'
  //   ? <div>This league does not exist.</div>
  //   : league
  //     ? <div>Behold, {league.name ?? 'a league!'}</div>
  //     : <div className="loading">Loading...</div>

  return (
    <main className="container-main">
      <div className="row mb-4">
        <div className="col">
          <Link to="/dashboard/leagues">
            {'<'} Back to Leagues
          </Link>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">
          <h1>{title}</h1>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-10">
          {!league ? (
            <div className="loading">Loading...</div>
          ) : (
            <Form noValidate>
              <div className="row">
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body my-4 text-center">
                      <ImageUploader imageUrl={league.leaguePicture} onUpload={handleImageUpload} />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <Form.Group className="form-floating mb-3" controlId="name">
                    <FormControl
                      type="text"
                      className="form-control form-input-top"
                      isInvalid={errors?.name?.invalid}
                      placeholder="Name"
                      value={league.name}
                      onChange={(e) =>
                        setLeague({ ...league, name: e.target.value })
                      }
                    />
                    <FormLabel>Name</FormLabel>
                  </Form.Group>
                  <Form.Group className="form-floating mb-3" controlId="password">
                    <FormControl
                      type="password"
                      className="form-control form-input-top"
                      isInvalid={errors?.password?.invalid}
                      placeholder="Password"
                      value={league.password}
                      onChange={(e) =>
                        setLeague({ ...league, password: e.target.value })
                      }
                    />
                    <FormLabel>Password</FormLabel>
                  </Form.Group>
                </div>
                <div className="col-md-4">
                  <Form.Group controlId="status">
                    <FormSelect
                        className="form-control mb-3"
                        value={league.status}
                        isInvalid={errors?.status?.invalid}
                        onChange={(e) =>
                            setLeague({ ...league, status: e.target.value })
                        }
                    >
                        <option value="">Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                    </FormSelect>
                  </Form.Group>
                  <FormErrors />

                  <div className="date-picker">
                    <label htmlFor="startDate">League start date</label>
                    <DatePicker id="startDate" className={'form-control py-3' + (errors?.startDate?.invalid ? ' is-invalid' : '')} selected={league.startDate ? new Date(league.startDate) : null} onChange={(date) => setLeague({ ...league, startDate: date })} />
                  </div>
                  <div>First event: Koopa Beach Open</div>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 col-md-8">
          <SubmitButton />
          <div className="mt-4">
            <FormErrors />
          </div>
        </div>
      </div>
    </main>
  );
}

export default League;
