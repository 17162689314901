import { NavLink, useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import logo from './logo.svg';
import "./my-picks.css";
import { dbStore } from "../services/api/db-store.service";
import { Spinner } from "react-bootstrap";

import {
  collection, doc, onSnapshot
} from "firebase/firestore";
import { getCardinalSuffix } from "../app/helpers/string.helpers";
import { Helmet } from 'react-helmet';
import { getLeaderboard, getUserPicksResults } from "../services/Scoring";
import { SeasonContext } from "../contexts/SeasonsContext";

const ROUND_ABBREVIATIONS = {
  'Opening Round': 'OR',
  'Elimination Round': 'ER',
  'Round of 32': 'R32',
  'Round of 16': 'R16',
  'Quarterfinals': 'QF',
  'Semifinals': 'SF',
  'Finals': 'F',
};

function MyPicks() {

  const { userId, eventId, season, leagueId: leagueIdParam } = useParams();

  const [leagueId, setLeagueId] = useState(leagueIdParam);

  const [countries, setCountries] = useState(null);
  const [leagues, setLeagues] = useState(null);
  const [events, setEvents] = useState(null);
  
  const [event, setEvent] = useState(null);
  const [user, setUser] = useState(null);
  
  const [userLeagues, setUserLeagues] = useState(null);
  const [userScores, setUserScores] = useState(null);
  const [userPicks, setUserPicks] = useState(null);
  const [sortedPicks, setSortedPicks] = useState(null);
  
  const [eventPicks, setEventPicks] = useState(null);
  const [eventResults, setEventResults] = useState(null);
  
  const [leaderboard, setLeaderboard] = useState(null);
  const [userRank, setUserRank] = useState(null);
  const [userEventScore, setUserEventScore] = useState(null);

  const [unsubscribeEventResults, setUnsubscribeEventResults] = useState(() => () => {});
  const [unsubscribeUserScores, setUnsubscribeUserScores] = useState(() => () => {});

  // Get the countries and leagues at the start
  useEffect(() => {
    dbStore.getDocuments('countries')
      .then((countries) => {
        const countriesDict = countries.reduce((dict, c) => {
          dict[c.tricode] = c;
          return dict;
        }, [])
        setCountries(countriesDict);
      });

    dbStore.getDocumentsDict('leagues')
      .then((leagues) => {
        setLeagues(leagues);
      });

    const startDate = new Date(`${season}/01/01`);
    const endDate = new Date(`${season}/12/31`);
    dbStore.getDocuments('events', [['startDate', '>=', startDate], ['startDate', '<=', endDate]])
      .then((events) => {
        setEvents(events.sort((a, b) => 
          new Date(a.startDate ?? 0).getTime() -
          new Date(b.startDate ?? 0).getTime()))
      });
  }, [season]);

  useEffect(() => {
    setLeagueId(leagueIdParam);
  }, [leagueIdParam]);


  // Whenever leagues, or picks change, filter the relevant leagues and set them to the state
  useEffect(() => {
    if (!leagues || !userPicks) { return; }

    const userLeagues = Object.values(leagues).filter((l) => userPicks.some((p) => p.leagueId === l.id));
    setUserLeagues(userLeagues);
    if (userLeagues.length === 1) {
      setLeagueId(userLeagues[0].id);
    }
  }, [leagues, userPicks])


  // Whenever the userId, eventId, or leagueId change, get the picks, results, user, and event.
  useEffect(() => {
    if (userId && userId !== user?.id) {
      dbStore.getDocument('users', userId)
        .then((user) => {
          setUser(user);
        });
    }

    if (eventId && eventId !== event?.id) {
      dbStore.getDocument('events', eventId)
        .then((event) => {
          setEvent(event);
        });
    }

    if (userId && eventId && leagueId) {
      unsubscribeEventResults?.();
      unsubscribeUserScores?.();

      setUserPicks([]);
      
      dbStore.getDocuments('picks', [['eventId', '==', eventId], ['leagueId', '==', leagueId]])
        .then((picks) => {
          if (!picks) {
            setEventPicks([]);
            setUserPicks([]);
            return;
          }
          // const leagues = [...new Set(picks.map((p) => p.leagueId))];
          // const firstLeague = leagues[0];
          // if (!firstLeague) { setPicks([]); return; }
          // const filteredPicks = picks.filter((p) => p.leagueId === firstLeague);
          setEventPicks(picks);
          const userPicks = picks.filter((p) => p.userid === userId);
          setUserPicks(userPicks);
        });
      
      const db = dbStore.db;

      const resultsDocRef = doc(db, `eventResults/${eventId}`);
      
      const newUnsubscribeEventResults = onSnapshot(resultsDocRef, (doc) => {
        if (doc.exists()) {
          const results = doc.data();
          setEventResults(results || []);
        }
      }, (error) => {
        console.error('Error subscribing to the document:', error);
      });
      setUnsubscribeEventResults(() => newUnsubscribeEventResults);

      const userScoresCollectionRef = collection(db, 'userScores');
      const newUnsubscribeUserScores = onSnapshot(userScoresCollectionRef, (querySnapshot) => {
        const userScores = {};
        querySnapshot.forEach((doc) => {
          userScores[doc.id] = doc.data();
        });
        setUserScores(userScores);
        console.log(userScores);
      }, (error) => {
        console.error('Error subscribing to the document:', error);
      });
      setUnsubscribeUserScores(() => newUnsubscribeUserScores);

      return () => {
        console.log('unsubbing');
        newUnsubscribeEventResults();
        newUnsubscribeUserScores();
      }
    } else if (!leagueId && userId && userId !== user?.id) {
      dbStore.getDocuments('picks', [['eventId', '==', eventId], ['userid', '==', userId]])
        .then((picks) => {
          if (!picks) {
            setUserPicks([]);
            return;
          }
          // const leagues = [...new Set(picks.map((p) => p.leagueId))];
          // const firstLeague = leagues[0];
          // if (!firstLeague) { setPicks([]); return; }
          // const filteredPicks = picks.filter((p) => p.leagueId === firstLeague);
          setUserPicks(picks);
        });
    } else {
      setUserPicks(null);
    }
  }, [userId, eventId, leagueId]);


  // Whenever the userScores, userId, or leagueId change, get the user's rank and the leaderboard
  useEffect(() => {
    if (!userId || !eventResults || !eventPicks) { return; }
    
    const leaderboard = getLeaderboard(eventResults, eventPicks);
    const rank = leaderboard.findIndex((s) => s.userId === userId) + 1;

    setLeaderboard(leaderboard);
    setUserRank(rank);
    setUserEventScore(leaderboard.find((s) => s.userId === userId)?.score ?? 0);
  }, [userId, eventResults, eventPicks])


  // Whenever the userPicks, results, or leagueId change, sort the user's picks and calculate the display info
  useEffect(() => {
    if (!userPicks || !eventResults || !leagueId) { return; }
    
    setSortedPicks(getUserPicksResults(userPicks, eventResults, leagueId));
  }, [userPicks, eventResults, leagueId]);


  const formatUserEventScore = () => {
    return Number(userEventScore).toFixed(2);
  }

  const title = 'Results';

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="main-container mypicks">
        <div className="header row justify-content-between align-items-center py-2 px-3">

          <div className="col-sm-4 header-left d-flex align-items-center">
            <img src={logo} alt="" className="navbar-logo me-2" />
            <h4 className="header-title">Sportsmash</h4>
          </div>

          <div className="col-sm-8 header-right d-flex align-items-center">
            {leagueId && eventId && leagues?.[leagueId] && (<h5>
              {userLeagues?.length > 1 &&<i className="bi bi-arrow-left"></i>} <NavLink to={`/mypicks/${userId}/${season}/${eventId}`}>{leagues[leagueId].name}</NavLink>
            </h5>)}
            {leagueId && eventId && leagues?.[leagueId] && event?.name && <span className="mx-3 d-none d-sm-inline">|</span>}
              <NavLink to={`/mypicks/${userId}/${season}`}>
                <div className="event-info d-flex align-items-center">
                  {eventId && countries && event && (<img className="event-country-flag me-2" src={countries[event.country]?.flag} alt={event.country} />)}
                  { eventId ? <h5>{event?.name}</h5> : <h5>Select an Event</h5> }
                </div>
              </NavLink>
          </div>
        </div>

        <div className="mypicks-section">
          <div className="container">
            <div className="main-title">
              <h1 className="side-lines">{title}</h1>
            </div>
          </div>
        </div>

        <div className="mypicks-section">
          <div className="container">
            <div className="row mb-3">
              <div className="col">
                <div className="manager-info d-flex align-items-center">
                  <div className="manager-image circle-image-frame border me-2">
                    <img src={user?.profilePic} alt="" />
                  </div>
                  <h2 className="m-0">{user?.name}</h2>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                {leagueId && eventId ? (
                <div className="row border mb-3 flex-row">
                  <div className="info-section col-sm-8 justify-content-between py-3">
                      <h5 className="w-25 color-secondary">Event Score</h5>
                      <h4 className="points athlete-points">{formatUserEventScore()}</h4>
                  </div>
                  <div className="info-section col-sm-4 justify-content-between py-3">
                      <h5 className="color-secondary">Rank</h5>
                      <h5 className="athlete-rank">{userRank}/{leaderboard?.length}</h5>
                  </div>
                </div>
                )
                : eventId ? <h2 className="text-center side-lines">Select a league</h2>
                : <h2 className="text-center side-lines">Select an event</h2>}
              </div>
            </div>
          </div>
        </div>

        {leagueId && eventId && userPicks && eventResults ? (
          <>
          {sortedPicks?.results?.length > 0 ? (<>
            <div className="mypicks-section section-active">
              <div className="container">
                <h2 className="side-lines"><span>Final Results</span></h2>
                {sortedPicks.results.map((pick, pickIdx) => (
                  <div className="row" key={`result-${pickIdx}`}>
                    <div className="col">
                      <div className="row border d-flex p-0 mb-3">
                        <div className="col">
                          <div className="athlete-image d-none">
                            <img src={pick.profilePic} alt={pick.tricode} />
                          </div>
                          <div className="athlete-info d-flex flex-column">
                            <div className="d-flex justify-content-between info-section py-3">
                                <h3 className="athlete-name">
                                  <img className="pick-country-flag" src={countries[pick.country]?.flag} alt={pick.country} />
                                  {pick.firstName?.[0]}. {pick.lastName}
                                </h3>
                                <h5 className="athlete-rank">{pick.position}<sup>{getCardinalSuffix(pick.position)}</sup> place</h5>
                            </div>
                            <div className="d-flex justify-content-between info-section">
                                <h5 className="color-secondary">Points</h5>
                                <h4 className="points athlete-points">{Number(pick.fantasyPoints).toFixed(2)}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>)
          : ''}

          {sortedPicks?.active?.length > 0 ? (<>
            <div className="mypicks-section section-active">
              <div className="container">
                <h2 className="side-lines"><span>Active Surfers</span></h2>
                {sortedPicks.active.map((pick, pickIdx) => (
                  <div className="row" key={`active-${pickIdx}`}>
                    <div className="col">
                      <div className="row border d-flex p-0 mb-3">
                        <div className="col px-0">
                          <div className="athlete-image d-none">
                            <img src={pick.profilePic} alt={pick.name} />
                          </div>
                          <div className="athlete-info d-flex flex-column">
                            <div className="d-flex justify-content-between align-items-end info-section py-3">
                              <div className="d-flex align-items-start">
                                <img className="pick-country-flag" src={countries[pick.country]?.flag} alt={pick.country} />
                                <div>
                                  <h3 className="athlete-name">
                                    <span className="d-inline-flex">
                                      {pick.firstName?.[0]}. {pick.lastName}
                                    </span>
                                  </h3>
                                  <span><small className="text-muted">Heat {pick.lastHeatNumber}</small></span>
                                </div>
                              </div>

                                <div className="text-end">
                                  <h5 className="athlete-rank">{ROUND_ABBREVIATIONS[pick.lastRoundName] ?? pick.lastRoundName}</h5>
                                  <span className="text-muted">Heat Average {Number(pick.averageHeatScore).toFixed(2)}</span>
                                </div>
                            </div>
                            {pick.lastRound !== 1 || !!pick.averageHeatScore ? (
                              <div className="d-flex justify-content-between info-section">
                                  <h5 className="color-secondary">Provisional Points</h5>
                                  <h4 className="points athlete-points">{Number(pick.fantasyPoints).toFixed(2)}</h4>
                              </div>
                            ) : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>)
          : ''}

          {sortedPicks?.eliminated?.length > 0 ? (<>
            <div className="mypicks-section section-eliminated">
              <div className="container">
                <h2 className="side-lines"><span>Eliminated Surfers</span></h2>
                {sortedPicks.eliminated.map((pick, pickIdx) => (
                  <div className="row" key={`eliminated-${pickIdx}`}>
                    <div className="col">
                      <div className="row border d-flex p-0 mb-3">
                        <div className="col px-0">
                          <div className="athlete-image d-none">
                            <img src={pick.profilePic} alt={pick.name} />
                          </div>
                          <div className="athlete-info d-flex flex-column">
                            <div className="d-flex justify-content-between info-section py-3">
                              <div>
                                <h3 className="athlete-name">
                                  <img className="pick-country-flag" src={countries[pick.country]?.flag} alt={pick.country} />
                                  {pick.firstName?.[0]}. {pick.lastName}
                                </h3>
                              </div>

                              <div>
                                <h5 className="athlete-rank">{pick.position}<sup>{getCardinalSuffix(pick.position)}</sup> place</h5>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between info-section">
                                <h5 className="color-secondary">Points</h5>
                                <h4 className="points athlete-points">{Number(pick.fantasyPoints).toFixed(2)}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>)
          : ''}
          </>
        ) : leagueId && eventId
        ? <div className="p-4 text-center">
          <Spinner className="text-light" as="span" animation="border" size="md" role="status" aria-hidden="true"></Spinner>
        </div>
        : eventId ? <div className="select-a-league mypicks-section">
          <div className="container">
            <ul className="leagues-list">
              {userLeagues?.map((league, leagueIdx) => (
                <li key={'league-' + leagueIdx}>
                  <NavLink to={`/mypicks/${userId}/${season}/${eventId}/${league.id}`}>
                    {league.name} <i className="bi bi-arrow-right"></i>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        : <div className="select-an-event mypicks-section">
          <div className="container">
            <ul className="leagues-list">
              {events?.map((event, eventIdx) => (
                <li key={'event-' + eventIdx}>
                  <NavLink to={`/mypicks/${userId}/${season}/${event.id}`}>
                    <img className="event-country-flag me-2" src={countries?.[event.country]?.flag} alt={event.country} />
                    {event.name} <i className="bi bi-arrow-right"></i>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        }
      </div>
    </>
  );
}

export default MyPicks;