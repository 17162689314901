import firebaseapp from "../../config/firebaseapp";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";

export class FunctionsService {
  constructor() {
    this.app = firebaseapp;
    this.functions = getFunctions(this.app);
    if (process.env.NODE_ENV === 'development') {
      connectFunctionsEmulator(this.functions, 'localhost', 5001);
    }
  }



  async getAthleteScores(athleteId, eventId) {
    try {
      // Call the onCall function and pass in the athleteId and eventId parameters
      const result = await httpsCallable(this.functions, 'getAthleteScores')({
        athleteId,
        eventId,
      });

      // Return the result of the onCall function
      return result.data;
    } catch (error) {
      // Handle any errors that occur during the function call
      console.error(error);
      throw error;
    }
  }



  async getPicksScores(userId, eventId) {
    try {
      // Call the onCall function and pass in the athleteId and eventId parameters
      const result = await httpsCallable(this.functions, 'getPicksScores')({
        userId,
        eventId,
      });

      // Return the result of the onCall function
      return result.data;
    } catch (error) {
      // Handle any errors that occur during the function call
      console.error(error);
      throw error;
    }
  }

  async updateEventScores(eventId) {
    try {
      const result = await httpsCallable(this.functions, 'updateEventScores')({
        eventId,
      });

      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateUserScores() {
    try {
      const result = await httpsCallable(this.functions, 'updateUserScores')();

      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getUserScores(userId, leagueId, eventIds = []) {
    try {
      const result = await httpsCallable(this.functions, 'getUserScores')({
        userId,
        leagueId,
        eventIds,
      });

      return result.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }


  static instance;

  /**
   * It creates a singleton instance of the DBStoreService class.
   * @returns The instance of the DBStoreService class.
   */
  static getInstance() {
    FunctionsService.instance = FunctionsService.instance ?? new FunctionsService();
    return FunctionsService.instance;
  }
}

export const functions = FunctionsService.getInstance();