import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { dbStore } from "../../services/api/db-store.service";
import { functions } from "../../services/api/functions.service";
import {
  Button,
  Form,
  FormCheck,
  FormControl,
  FormLabel,
  FormSelect,
  Spinner,
} from "react-bootstrap";
import formValidation from "../common/validation";
import { CountriesContext } from "../../contexts/CountriesContext";
import { EventContext } from "../../contexts/EventContext";
import { Typeahead } from "react-bootstrap-typeahead";
import ImageUploader from "../common/ImageUploader";
import { uploadImageToFirestore } from "../../services/api/storage.service";
import { SeasonContext } from "../../contexts/SeasonsContext";

function Athlete() {
  const { id } = useParams();
  const [athlete, setAthlete] = useState(null);
  const [allAthletes, setAllAthletes] = useState([]);
  const [athletes, setAthletes] = useState([]);
  const [events, setEvents] = useState([]);
  const [originalName, setOriginalName] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { countries } = useContext(CountriesContext);

  const { season, setSeason } = useContext(SeasonContext);



  const { event: globalEvent } = useContext(EventContext);

  useEffect(() => {
    const startDate = new Date(`${season}/01/01`);
    const endDate = new Date(`${season}/12/31`);
    dbStore.getDocuments('events', [['startDate', '>=', startDate], ['startDate', '<=', endDate]])
      .then((events) => setEvents(
        events.sort((a, b) =>
          new Date(a.startDate ?? 0).getTime() -
          new Date(b.startDate ?? 0).getTime()
      )));
    dbStore.getDocuments('athletes')
      .then((athletes) => {
        setAllAthletes(athletes);
      });
  }, [season]);

  useEffect(() => {
    setIsLoading(true);
    // load athlete from API if id is present
    if (id) {
      dbStore.getDocument("athletes", id).then((data) => {
        if (!data) {
          navigate("/error-404");
        } else {
          setAthlete(data);
          setOriginalName(`${data.firstName} ${data.lastName}`);
          setIsLoading(false);
        }
      });

      if (globalEvent) {
        functions.getAthleteScores(id, globalEvent.id).then((data) => {
          console.log(data);
        });
      }
      
    } else {
      setIsLoading(false);
      setAthlete({});
    }
  }, [id]);

  useEffect(() => {
    if (athlete && allAthletes.length) { updateAthletes(allAthletes, athlete.gender); }
  }, [athlete, allAthletes]);

  const updateAthletes = (athletes, gender) => {
    const genderAthletes = athletes.filter((a) => a.gender === gender);
    setAthletes(genderAthletes);
  }

  //form field validators
  const validators = {
    firstName: {
      required: {
        value: true,
      },
    },
    lastName: {
      required: {
        value: true,
      },
    },
    tricode: {
      required: {
        value: true,
      },
    },
    country: {
      required: {
        value: true,
      },
    },
    gender: {
      required: {
        value: true,
      },
    },
    stance: {
      required: {
        value: true,
      },
    },
    status: {
      required: {
        value: true,
      },
    },
    wildcardType: {
      custom: {
        isValid: (value) => (athlete.wildcard ? !!value : !value),
        message: "Invalid wildcard type",
      },
    },
    replacing: {
      custom: {
        isValid: (value) => (athlete.wildcard && athlete.wildcardType === 'Injury' ? value?.id : !value?.id),
        message: "Must select an athlete to replace",
      },
    },
    event: {
      custom: {
        isValid: (value) => (athlete.wildcard ? !!value : !value),
        message: "Must specify an event",
      },
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { valid, errors } = formValidation(athlete, validators);

    if (!valid) {
      setErrors(errors);
      return;
    }

    setErrors({});

    //if no errors
    handleSave();
  };

  const handleSave = async () => {
    setIsLoading(true);

    if (id) {
      await dbStore.updateDoc("athletes", id, athlete);
      // setOriginalName(`${athlete.firstName} ${athlete.lastName}`);
    } else {
      const newId = await dbStore.addDoc("athletes", athlete);
      // navigate("/dashboard/athlete/" + newId);
    }
    navigate("/dashboard/athletes");

    setIsLoading(false);
  };

  const title = isLoading ? "Loading" : originalName ?? "New Athlete";

  const FormErrors = () => (
    <div>
      {Object.entries(errors).map(([key, error]) => (
        <div className="text-danger" key={key}>
          {error?.message}
        </div>
      ))}
    </div>
  );

  const SubmitButton = () => (
    <Button
      className="btn btn-lg btn-primary"
      type="button"
      disabled={isLoading}
      onClick={handleSubmit}
    >
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        hidden={!isLoading}
      />
      <span className="px-2">Save</span>
    </Button>
  );

  // return athlete === 'error'
  //   ? <div>This athlete does not exist.</div>
  //   : athlete
  //     ? <div>Behold, {athlete.name ?? 'a athlete!'}</div>
  //     : <div className="loading">Loading...</div>

  const handleImageUpload = async (image) => {
    const url = await uploadImageToFirestore(image, 'athletes');
    setAthlete({ ...athlete, profilePic: url });
  }

  return (
    <main className="container-main">

      <div className="row mb-2">
        <div className="col">
          <h1>{title}</h1>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <Link to="/dashboard/athletes">
            {'<'} Back to Athletes
          </Link>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-10">
          {!athlete ? (
            <div className="loading">Loading...</div>
          ) : (
            <Form noValidate>
              <div className="row">
                <div className="col-md-6">
                  <div className="card mb-4">
                    <div className="card-body my-4 text-center">
                      <ImageUploader imageUrl={athlete.profilePic} onUpload={handleImageUpload} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group
                        className="form-floating"
                        controlId="firstName"
                      >
                        <FormControl
                          type="text"
                          className="form-control form-input-top"
                          isInvalid={errors?.firstName?.invalid}
                          placeholder="First Name"
                          value={athlete.firstName ?? ''}
                          onChange={(e) =>
                            setAthlete({
                              ...athlete,
                              firstName: e.target.value,
                            })
                          }
                        />
                        <FormLabel>First Name</FormLabel>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        className="form-floating"
                        controlId="lastName"
                      >
                        <FormControl
                          type="text"
                          className="form-control form-input-top"
                          isInvalid={errors?.lastName?.invalid}
                          placeholder="Last Name"
                          value={athlete.lastName ?? ''}
                          onChange={(e) =>
                            setAthlete({ ...athlete, lastName: e.target.value })
                          }
                        />
                        <FormLabel>Last Name</FormLabel>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <Form.Group className="form-floating" controlId="tricode">
                        <FormControl
                          type="text"
                          className="form-control form-input-top"
                          isInvalid={errors?.tricode?.invalid}
                          placeholder="Tricode"
                          value={athlete.tricode ?? ''}
                          onChange={(e) =>
                            setAthlete({ ...athlete, tricode: e.target.value })
                          }
                        />
                        <FormLabel>Tricode</FormLabel>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <Form.Group controlId="country">
                        <FormSelect
                          className="form-control form-input-top"
                          isInvalid={errors?.country?.invalid}
                          value={athlete.country ?? ''}
                          onChange={(e) =>
                            setAthlete({ ...athlete, country: e.target.value })
                          }
                        >
                          <option value="">Select a Country</option>
                          {countries.map((country) => (
                            <option key={country.name} value={country.tricode}>
                              {country.name}
                            </option>
                          ))}
                        </FormSelect>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <Form.Group controlId="gender">
                        <FormSelect
                          className="form-control form-input-top"
                          value={athlete.gender ?? ''}
                          isInvalid={errors?.gender?.invalid}
                          onChange={(e) =>
                            setAthlete({ ...athlete, gender: e.target.value })
                          }
                        >
                          <option value="">Gender</option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                        </FormSelect>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <Form.Group controlId="stance">
                        <FormSelect
                          className="form-control form-input-top"
                          value={athlete.stance ?? ''}
                          isInvalid={errors?.stance?.invalid}
                          onChange={(e) =>
                            setAthlete({ ...athlete, stance: e.target.value })
                          }
                        >
                          <option value="">Stance</option>
                          <option value="Regular">Regular</option>
                          <option value="Goofy">Goofy</option>
                        </FormSelect>
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <Form.Group controlId="status">
                        <FormSelect
                          className="form-control form-input-top"
                          value={athlete.status ?? ''}
                          isInvalid={errors?.status?.invalid}
                          onChange={(e) =>
                            setAthlete({ ...athlete, status: e.target.value })
                          }
                        >
                          <option value="">Status</option>
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                          <option value="Injured">Injured</option>
                          <option value="Susspended">Susspended</option>
                          <option value="Eliminated">Eliminated</option>
                        </FormSelect>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <FormCheck
                            type="checkbox"
                            id="wildcard"
                            label="Wildcard"
                            checked={athlete.wildcard}
                            onChange={(e) =>
                              setAthlete({
                                ...athlete,
                                wildcard: e.target.checked,
                              })
                            }
                          />
                          <Form.Group controlId="wildcardType">
                            <FormSelect
                              className="form-control form-input-top mt-4"
                              value={athlete.wildcardType ?? ''}
                              isInvalid={errors?.wildcardType?.invalid}
                              onChange={(e) =>
                                setAthlete({
                                  ...athlete,
                                  wildcardType: e.target.value,
                                })
                              }
                            >
                              <option value="">Wildcard Type</option>
                              <option value="Event">Event</option>
                              <option value="Injury">Injury</option>
                            </FormSelect>
                          </Form.Group>
                          
                          <Form.Group className="mt-4" controlId="replacing">
                            <Typeahead
                              id="replacing"
                              labelKey={(athlete) =>
                                athlete?.firstName
                                  ? `${athlete.firstName} ${athlete.lastName}`
                                  : ""
                              }
                              onChange={(selections) => {
                                setAthlete({
                                  ...athlete,
                                  replacing: selections[0] ?? '',
                                });
                              }}
                              options={athletes}
                              placeholder="Replacing"
                              emptyLabel={allAthletes.length ? "Please select athlete gender" : "No athletes found"}
                              defaultSelected={[athlete?.replacing ?? {}]}
                              isInvalid={errors?.replacing?.invalid}
                            />
                          </Form.Group>

                          <Form.Group controlId="event">
                            <FormSelect
                              className="form-control form-input-top mt-4"
                              value={athlete.event ?? ''}
                              isInvalid={errors?.event?.invalid}
                              onChange={(e) => {
                                setAthlete({
                                  ...athlete,
                                  event: e.target.value,
                                });
                              }}
                            >
                              <option value="">Event</option>
                              {events.map((event) => (
                                <option key={event.id} value={event.id}>
                                  {event.name}
                                </option>
                              ))}
                            </FormSelect>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 col-md-8">
          <SubmitButton />
          <div className="mt-4">
            <FormErrors />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Athlete;
