import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import logo from './logo.svg';
import { useEffect, useState } from "react";
import { dbStore } from "../services/api/db-store.service";
import {
  collection, onSnapshot
} from "firebase/firestore";

function Top5() {
  const { userId } = useParams();

  const [user, setUser] = useState(null);
  const [userPicks, setUserPicks] = useState(null);
  const [eventsResults, setEventsResults] = useState(null);
  const [unsubscribeEventsResults, setUnsubscribeEventsResults] = useState(() => () => {});

  // Whenever the userId, eventId, or leagueId change, get the picks, results, user, and event.
  useEffect(() => {
    if (userId) {
      dbStore.getDocument('users', userId)
        .then((user) => {
          setUser(user);
        });
    }

    if (userId) {
      unsubscribeEventsResults?.();

      setUserPicks([]);
      
      dbStore.getDocuments('topfivepicks', [['userid', '==', userId]])
        .then((picks) => {
          if (!picks) {
            setUserPicks([]);
            return;
          }
          setUserPicks(picks);
          console.log(picks)
        });
      
      const db = dbStore.db;

      const eventResultsCollectionRef = collection(db, 'eventResults');
      const newUnsubscribeEventsResults = onSnapshot(eventResultsCollectionRef, (querySnapshot) => {
        const eventsResults = {};
        querySnapshot.forEach((doc) => {
          eventsResults[doc.id] = doc.data();
        });
        setEventsResults(eventsResults);
        console.log(eventsResults);
      }, (error) => {
        console.error('Error subscribing to the document:', error);
      });
      setUnsubscribeEventsResults(() => newUnsubscribeEventsResults);

      return () => {
        console.log('unsubbing');
        newUnsubscribeEventsResults();
      }
    } else {
      setUserPicks(null);
    }
  }, [userId]);
  
  const title = 'Top 5 Picks';

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="main-container mypicks">
        <div className="header row justify-content-between align-items-center py-2 px-3">

          <div className="col-sm-4 header-left d-flex align-items-center">
            <img src={logo} alt="" className="navbar-logo me-2" />
            <h4 className="header-title">Sportsmash</h4>
          </div>
        </div>

        <div className="mypicks-section">
          <div className="container">
            <div className="main-title">
              <h1 className="side-lines">{title}</h1>
            </div>
          </div>
        </div>

        <div className="mypicks-section">
          <div className="container">
            <div className="row mb-3">
              <div className="col">
                <div className="manager-info d-flex align-items-center">
                  <div className="manager-image circle-image-frame border me-2">
                    <img src={user?.profilePic} alt="" />
                  </div>
                  <h2 className="m-0">{user?.name}</h2>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="row border mb-3 flex-row">
                  <div className="info-section col-sm-8 justify-content-between py-3">
                      <h5 className="w-25 color-secondary">Overall Score</h5>
                      <h4 className="points athlete-points">222.22</h4>
                  </div>
                  <div className="info-section col-sm-4 justify-content-between py-3">
                      <h5 className="color-secondary">Rank</h5>
                      <h5 className="athlete-rank">3/3</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Top5;