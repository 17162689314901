import { dbStore } from './services/api/db-store.service';
import Sport from './components/edit-screens/Sport';
import Athlete from './components/edit-screens/Athlete';
import League from './components/edit-screens/League';
import Event from './components/edit-screens/Event';
import User from './components/edit-screens/User';
import Country from './components/edit-screens/Country';

const ENTITIES = [
  {
    path: 'sports',
    icon: 'bi-tsunami',
    title: 'Sports',
    entityNamePlural: 'sports',
    entityNameSingular: 'sport',
    getItems: () => dbStore.getDocuments('sports', [], ['name', 'asc']),
    deleteItem: (sport) => dbStore.deleteDoc('sports', sport.id),
    editScreen: Sport,
  },
  {
    path: 'countries',
    icon: 'bi-globe',
    title: 'Countries',
    fields: [
      { fieldName: 'Name', value: 'name' },
      { fieldName: 'Code', value: 'tricode' },
      { fieldName: 'Flag', type: 'image', value: (item) => item.flag, sort: false },
    ],
    entityNamePlural: 'countries',
    entityNameSingular: 'country',
    getItems: () => dbStore.getDocuments('countries', [], ['name', 'asc']),
    deleteItem: (country) => dbStore.deleteDoc('countries', country.id),
    editScreen: Country,
  },
  {
    path: 'athletes',
    icon: 'bi-file-person',
    title: 'Athletes',
    entityNamePlural: 'athletes',
    entityNameSingular: 'athlete',
    fields: [
      { fieldName: '', type: 'image', value: (item) => item.profilePic, sort: false },
      { fieldName: 'Name', value: (athlete) => `${athlete.firstName} ${athlete.lastName}${athlete.tricode ? ` (${athlete.tricode})` : ''}` },
      { fieldName: 'Status', value: 'status' },
      { fieldName: 'Country', value: (athlete) => athlete.country },
      { fieldName: '', type: 'image', value: (item) => item.countryObj?.flag, sort: false },
      { fieldName: 'Gender', value: 'gender' },
      { fieldName: 'Wildcard Type', value: (athlete) => athlete.wildcardType || '' },
    ],
    filters: {
      Status: {
        options: [
          { label: 'Active', value: 'Active' },
          { label: 'Inactive', value: 'Inactive' },
        ],
        filterFn: (athlete, status) =>  athlete.status === status,
      },
      Gender: {
        options: [
          { label: 'Male', value: 'M' },
          { label: 'Female', value: 'F' },
        ],
        filterFn: (athlete, gender) => athlete.gender === gender,
      },
      Country: {
        optionsAsync: {
          promise: () => dbStore.getDocuments('countries'),
          map: (country) => ({ label: country.name, value: country.tricode })
        },
        filterFn: (athlete, country) => athlete.country === country,
      }
    },
    actions: [
      {
        label: 'Set Active',
        action: async (athlete) => {
          const updatedAthlete = { ...athlete, status: 'Active' };
          await dbStore.updateDoc('athletes', athlete.id, updatedAthlete);
          return updatedAthlete;
        },
        show: (athlete) => athlete.status !== 'Active',
      },
      {
        label: 'Set Inactive',
        action: async (athlete) => {
          const updatedAthlete = { ...athlete, status: 'Inactive' };
          await dbStore.updateDoc('athletes', athlete.id, updatedAthlete);
          return updatedAthlete;
        },
        show: (athlete) => athlete.status !== 'Inactive',
      }
    ],
    getItems: async () => {
      const athletes = await dbStore.getDocuments('athletes', [], ['firstName', 'asc']);
      const countries = (await dbStore.getDocuments('countries'))
        .reduce((dict, country) => ({ ...dict, [country.tricode]: country }), {});
      return athletes.map((athlete) => ({ ...athlete, countryObj: countries[athlete.country] }));
    },
    deleteItem: (athlete) => dbStore.deleteDoc('athletes', athlete.id),
    editScreen: Athlete,
  },
  {
    path: 'leagues',
    icon: 'bi-shield',
    title: 'Leagues',
    entityNamePlural: 'leagues',
    entityNameSingular: 'league',
    getItems: () => dbStore.getDocuments('leagues', [], ['name', 'asc']),
    deleteItem: (league) => dbStore.deleteDoc('leagues', league.id),
    editScreen: League,
  },
  {
    path: 'events',
    icon: 'bi-calendar',
    title: 'Events',
    fields: [
      { fieldName: '#',
        value: 'eventNumber',
        isDefaultSorting: true,
      },
      { fieldName: '', type: 'image', value: (item) => item.countryObj?.flag, sort: false },
      { fieldName: 'Name', value: 'name' },
      { fieldName: 'Shortcode', value: 'shortCode' },
      {
        fieldName: 'Start Date',
        value: (event) => event.startDate && (new Date(event.startDate)).toLocaleDateString(),
        // sort: (a, b) => (new Date(a.startDate ?? 0)).getTime() - (new Date(b.startDate ?? 0)).getTime(),
        defaultSortingDirection: 'desc',
      },
      { fieldName: 'Status', value: 'status', sort: false },
    ],
    entityNamePlural: 'events',
    entityNameSingular: 'event',
    getItems: async (season) => {
      let whereClauses = [];
      if (season) {
        const startDate = new Date(`${season}/01/01`);
        const endDate = new Date(`${season}/12/31`);
        whereClauses = [['startDate', '>=', startDate], ['startDate', '<=', endDate]];
      }
      // const events = await dbStore.getDocuments('events', [['startDate', '>', new Date('2024/01/01')]]);
      const events = await dbStore.getDocuments('events', whereClauses);
      const countries = (await dbStore.getDocuments('countries'))
        .reduce((dict, country) => ({ ...dict, [country.tricode]: country }), {});
      return events
        .map((event) => ({ ...event, countryObj: countries[event.country] }));
    },
    deleteItem: (event) => dbStore.deleteDoc('events', event.id),
    editScreen: Event,
  },
  {
    path: 'users',
    icon: 'bi-person',
    title: 'Users',
    fields: [
      { fieldName: '', type: 'image', value: (item) => item.profilePic, sort: false },
      { fieldName: 'Name', value: 'name' },
      {
        fieldName: 'Email',
        value: 'email'
      }
    ],
    entityNamePlural: 'users',
    entityNameSingular: 'user',
    getItems: () => dbStore.getDocuments('users', [], ['name', 'asc']),
    deleteItem: (user) => dbStore.deleteDoc('users', user.id),
    editScreen: User,
  }
];

export default ENTITIES;