import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';

function ImageUploader({ onUpload, autoUpload = true, imageUrl }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setImage(imageUrl || null);
    setLoading(false);
  }, [imageUrl]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    return file;
  };

  const handleAutoUpload = (e) => {
    const image = handleImageChange(e);
    handleUpload(image);
  }

  const handleCancel = () => {
    setSelectedImage(null);
    setImage(imageUrl || null);
  };

  const handleUpload = (image) => {
    image = image || selectedImage;
    if (image) {
      setLoading(true);
      // Call the provided onUpload function and pass the selected image.
      if (typeof onUpload !== 'function') {
        console.error(`onUpload prop must be a function, received ${typeof onUpload}`);
        return;
      }
      onUpload(image);

      // Reset the selected image after uploading.
      setSelectedImage(null);
    }
  };

  return (
    <div>
      {image && <div>
        <img src={image} alt="Uploaded" style={{ maxWidth: '100%' }} />
        <div className="mt-2">
          <Button
            variant="outline-primary"
            onClick={() => setImage(null)}
          >
            Change Image
          </Button>
        </div>
      </div>}
      
      {!image && <div>
        <Form.Group controlId="imageUpload">
          <Form.Label>Upload an Image</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={autoUpload ? handleAutoUpload : handleImageChange}
            disabled={loading}
          />
        </Form.Group>

        <div className="mt-2">
          {selectedImage && loading && (
            <div
              style={{
                position: 'relative',
                display: 'inline-block',
                maxWidth: '100%',
              }}
            >
              <img
                src={URL.createObjectURL(selectedImage)}
                alt="Selected"
                style={{ maxWidth: '100%' }} />
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'rgba(255, 255, 255, 0.8)',
                }}
              >
                <Spinner animation="border" variant="primary" />
              </div>
            </div>
          )}
          {selectedImage && !loading && (
            <div>
              <img
                src={URL.createObjectURL(selectedImage)}
                alt="Selected"
                style={{ maxWidth: '100%' }}
              />
            </div>
          )}
        </div>

        {!selectedImage && imageUrl && (
          <div className="mt-2">
            <Button
              variant="outline-primary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        )}
        {selectedImage && !loading && <div className="mt-2">
          <Button
            variant="outline-primary"
            onClick={handleCancel}
            className="me-2"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleUpload}
          >
            Upload
          </Button>
        </div>}
      </div>}
    </div>
  );
}

export default ImageUploader;