import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import Footer from '../components/Footer';
import ConsoleNavbar from '../components/ConsoleNavbar';
import Sidebar from '../components/Sidebar';
import useAuth from '../hooks/useAuth';
import { Spinner } from 'react-bootstrap';
import { useState, useEffect } from 'react';

function DashboardLayout() {
  const auth = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    auth.isAuth()
      .then((isAuth) => {
        if (!isAuth) {
          navigate(`/login?redirect=${encodeURIComponent(pathname)}`);
        } else {
          setAuthenticated(true);
        }
      })
  }, []);

  if (authenticated) {
    return (
      <>
        <ConsoleNavbar />
        <div className="container-fluid">
          <div className="row">
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
              <Sidebar />
            </nav>
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <Outlet />
              <Footer />
            </main>
          </div>
        </div>
      </>
    );
  }
  return <Spinner className="text-center m-4 p-4" />;
}

export default DashboardLayout;
