import { connectStorageEmulator, getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import firebaseapp from "../../config/firebaseapp";

function getStorageRef(path) {
  // Get a reference to the Firebase Storage service
  const storage = getStorage(firebaseapp);
  if (process.env.NODE_ENV === 'development') {
    connectStorageEmulator(storage, 'localhost', 9199);
  }
  return ref(storage, path);
}

// Function to upload an image to Firebase Firestore
export async function uploadImageToFirestore(imageFile, destinationPath) {
  // Create a reference to the storage bucket and the desired file path

  const filePath = `${destinationPath}/${imageFile.name}`;
  const storageRef = getStorageRef(filePath);

  try {
    // Upload the image file to Firebase Storage
    await uploadBytes(storageRef, imageFile);

    // Get the download URL for the uploaded image
    const downloadURL = await getDownloadURL(storageRef);

    // Return the download URL
    return downloadURL;
  } catch (error) {
    // Handle any errors that occurred during the upload process
    console.error("Error uploading image to Firestore:", error);
    throw error;
  }
}