import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { dbStore } from "../../services/api/db-store.service";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormLabel,
  Row,
  Spinner,
} from "react-bootstrap";
import formValidation from "../common/validation";

function Sport() {
  const { id } = useParams();
  const [sport, setSport] = useState(null);
  const [originalName, setOriginalName] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    // load sport from api if id is present
    if (id) {
      dbStore.getDocument("sports", id).then((data) => {
        if (!data) {
          navigate("/error-404");
        } else {
          setSport(data);
          setOriginalName(data.name);
          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(false);
      setSport({});
    }
  }, [id]);

  //form field validators
  const validators = {
    name: {
      required: {
        value: true,
        message: "Name is required",
      },
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { valid, errors } = formValidation(sport, validators);

    if (!valid) {
      setErrors(errors);
      return;
    }

    setErrors({});

    //if no errors
    handleSave();
  };

  const handleSave = async () => {
    setIsLoading(true);

    if (id) {
      await dbStore.updateDoc("sports", id, sport);
      setOriginalName(sport.name);
    } else {
      const newId = await dbStore.addDoc("sports", sport);
      navigate('/dashboard/sport/' + newId);
    }

    setIsLoading(false);
  };

  const title = isLoading ? 'Loading' : (originalName ?? "New Sport");

  const FormErrors = () => (
    <div>
      {Object.entries(errors).map(([key, error]) => (
        <div className="text-danger" key={key}>
          {error.message}
        </div>
      ))}
    </div>
  );

  const SubmitButton = () => (
    <Button
      className="btn btn-lg btn-primary"
      type="button"
      disabled={isLoading}
      onClick={handleSubmit}
    >
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        hidden={!isLoading}
      />
      <span className="px-2">Save</span>
    </Button>
  );

  // return sport === 'error'
  //   ? <div>This sport does not exist.</div>
  //   : sport
  //     ? <div>Behold, {sport.name ?? 'a sport!'}</div>
  //     : <div className="loading">Loading...</div>

  return (
    <main className="container-main">
      <div className="row mb-4">
        <div className="col">
          <Link to="/dashboard/sports">
            {'<'} Back to Sports
          </Link>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">
          <h1>{title}</h1>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 col-md-8">
          {!sport ? (
            <div className="loading">Loading...</div>
          ) : (
            <Form noValidate>
              <Form.Group className="form-floating mb-2" controlId="sportName">
                <FormControl
                  type="text"
                  className="form-control form-input-top"
                  isInvalid={errors?.name}
                  placeholder="Sport Name"
                  value={sport.name}
                  onChange={(e) =>
                    setSport({ ...sport, name: e.target.value })
                  }
                />
                <FormLabel>Sport Name</FormLabel>
              </Form.Group>
            </Form>
          )}
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 col-md-8">
          <SubmitButton />
          <div className="mt-4">
            <FormErrors />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Sport;
