import { browserLocalPersistence, getAuth, setPersistence, signOut } from "firebase/auth";
import firebaseapp from "../config/firebaseapp";



async function getSession() {
  const auth = getAuth(firebaseapp);
  await setPersistence(auth, browserLocalPersistence);
  const user = auth.currentUser;
  return user;
}

async function isAuth() {
  return !!(await getSession());
}


async function logout() {
  const auth = getAuth(firebaseapp);
  await setPersistence(auth, browserLocalPersistence);
  signOut(auth).then(() => {
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
  });
}

// The useAuth hook is a wrapper to this service, make sure exported functions are also reflected
// in the useAuth hook.
export {
  isAuth, logout, getSession
};
