import { useContext, useEffect, useState } from "react";
import { EventContext, EventsContext } from "../../contexts/EventContext";
import { useParams, NavLink } from "react-router-dom";
import { dbStore } from "../../services/api/db-store.service";
import { Form, Button, Spinner, Nav } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import { cloneDeep } from "lodash";

import "./scores.css";
import { functions } from "../../services/api/functions.service";

function Scores() {
  const { gender } = useParams();
  const { event: globalEvent, setEvent: setGlobalEvent } =
    useContext(EventContext);
  const { events: globalEvents, setEvents: setGlobalEvents } = useContext(EventsContext);

  const [event, setEvent] = useState(null);
  const [eventNumber, setEventNumber] = useState(0);
  const [athletes, setAthletes] = useState({});
  const [allAthletes, setAllAthletes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [roundIndex, setRoundIndex] = useState(0);
  const [liveScoreHeatIdx, setLiveScoreHeatIdx] = useState(null);

  const fillAthletesDict = (allAthletes) => {
    const athletesDict = allAthletes
      .filter((a) => a.gender === (gender === 'male' ? 'M' : 'F'))
      .reduce((dict, athlete) => ({ ...dict, [athlete.id]: athlete }), {});
    setAthletes(athletesDict);
  }

  useEffect(() => {
    dbStore
      .getDocuments('athletes', [['status', '==', 'Active']])
        .then((athletes) => {
          setAllAthletes(athletes);
          fillAthletesDict(athletes);
        });

  }, [])

  // This will run every time either gender, globalEvent, or events change.
  useEffect(() => {
    setIsLoading(!globalEvent);

    fillAthletesDict(allAthletes);

    const sortedEvents = Object.entries(globalEvents)
      .map(([_, e]) => e)
      .sort(
        (a, b) => (a.eventNumber ?? 0) - (b.eventNumber ?? 0)
          // new Date(a.startDate ?? 0).getTime() -
          // new Date(b.startDate ?? 0).getTime()
      );

    if (!globalEvent) {
      return;
    }

    const indexOfEvent = globalEvent.eventNumber ? globalEvent.eventNumber - 1 : sortedEvents.findIndex((e) => e.id === globalEvent.id);

    setEventNumber(indexOfEvent + 1);

    if (indexOfEvent === -1) {
      return;
    }

    const getHeats = (numberOfHeats, athletesPerHeat) => {
      const heat = { scores: [], live: false };
      heat.scores[athletesPerHeat - 1] = null;
      heat.scores.fill(null);
      for (const index in heat.scores) {
        heat.scores[index] = { athlete: { new: true } };
      }
      const heats = [];
      heats[numberOfHeats - 1] = null;
      heats.fill(null);
      for (const index in heats) {
        heats[index] = cloneDeep(heat);
      }
      return heats;
    };

    let emptyRounds;

    if (indexOfEvent < 5) {
      emptyRounds = {
        male: [
          { name: "Opening Round", heats: getHeats(12, 3) },
          { name: "Elimination Round", heats: getHeats(4, 3) },
          { name: "Round of 32", heats: getHeats(16, 2) },
          { name: "Round of 16", heats: getHeats(8, 2) },
          { name: "Quarterfinals", heats: getHeats(4, 2) },
          { name: "Semifinals", heats: getHeats(2, 2) },
          { name: "Final", heats: getHeats(1, 2) },
        ],
        female: [
          { name: "Opening Round", heats: getHeats(6, 3) },
          { name: "Elimination Round", heats: getHeats(2, 3) },
          { name: "Round of 16", heats: getHeats(8, 2) },
          { name: "Quarterfinals", heats: getHeats(4, 2) },
          { name: "Semifinals", heats: getHeats(2, 2) },
          { name: "Final", heats: getHeats(1, 2) },
        ]
      };
    } else {
      emptyRounds = {
        male: [
          { name: "Opening Round", heats: getHeats(8, 3) },
          { name: "Elimination Round", heats: getHeats(8, 2) },
          { name: "Round of 16", heats: getHeats(8, 2) },
          { name: "Quarterfinals", heats: getHeats(4, 2) },
          { name: "Semifinals", heats: getHeats(2, 2) },
          { name: "Final", heats: getHeats(1, 2) },
        ],
        female: [
          { name: "Opening Round", heats: getHeats(4, 3) },
          { name: "Elimination Round", heats: getHeats(4, 2) },
          { name: "Quarterfinals", heats: getHeats(4, 2) },
          { name: "Semifinals", heats: getHeats(2, 2) },
          { name: "Final", heats: getHeats(1, 2) },
        ]
      };
    }

    const eventRounds = cloneDeep(emptyRounds);

    for (const [gender, genderRounds] of Object.entries(globalEvent.rounds ?? {})) {
      for (const roundKey in genderRounds) {
        eventRounds[gender][roundKey] = genderRounds[roundKey];
      }
    }

    const eventWithRounds = { ...cloneDeep(globalEvent), rounds: eventRounds }


    setEvent(eventWithRounds);

    let activeRound = eventRounds[gender].findIndex((r) => !r.completed);
    if (activeRound === -1) {
      activeRound = eventRounds[gender].length - 1;
    }

    setRoundIndex(activeRound);
  }, [globalEvent, globalEvents, gender]);

  const handleRoundChange = (delta) => {
    let newRound = roundIndex + delta;
    if (newRound < 0) {
      newRound = event.rounds[gender].length - 1;
    } else if (newRound >= event.rounds[gender].length) {
      newRound = 0;
    }
    setRoundIndex(newRound);
  };

  const handleSave = async () => {
    setIsLoading(true);
    await dbStore.updateDoc("events", event.id, event);
    await functions.updateEventScores(event.id);
    await functions.updateUserScores();
    setGlobalEvents(); // This will force the globalEvents to be updated.
    setGlobalEvent(event);
    setIsLoading(false);
  };

  const handleEndRound = async () => {
    return handleRoundStatusChange(true);
  };

  const handleReopenRound = async () => {
    return handleRoundStatusChange(false);
  }

  const handleRoundStatusChange = async (completed) => {
    const message = completed ? 'Do you want to mark this round as complete?'
      : 'Do you want to re-open this round? (Mark it as incomplete)';
    
    const confirmation = confirm(message);
    if (confirmation) {
      setIsLoading(true);
      const newEvent = cloneDeep(event);
      newEvent.rounds[gender][roundIndex].completed = completed;
      await dbStore.updateDoc("events", event.id, newEvent);
      setGlobalEvent(newEvent);
      setEvent(newEvent);
      setIsLoading(false);
    }
  };

  const handleLiveAthleteChange = (scoreIdx, selections) => {
    const newEvent = cloneDeep(event);
    newEvent.rounds[gender][roundIndex].heats[
      liveScoreHeatIdx
    ].scores[scoreIdx].athlete =
      selections[0] ?? {};
    delete newEvent.rounds[gender][roundIndex]
      .heats[liveScoreHeatIdx].scores[scoreIdx]
      .athlete.new;
    setEvent(newEvent);
  }

  const toggleLiveScoring = (heatIdx) => {
    setLiveScoreHeatIdx(heatIdx);
    const liveScoreHeat = event.rounds[gender]?.[roundIndex].heats?.[heatIdx];
    for (const score of liveScoreHeat?.scores ?? []) {
      score.scores = score.scores ?? [null, null];
    }
    // setLiveScoreHeat(liveScoreHeat);
  };

  const getLiveScoreHeat = () => {
    return event.rounds[gender]?.[roundIndex].heats?.[liveScoreHeatIdx];
  }

  const exitLiveScoring = () => {
    setLiveScoreHeatIdx(null);
  };

  const addLiveScoreForAthlete = (athleteIdx) => {
    const liveScoreHeat = getLiveScoreHeat();

    if (!liveScoreHeat.scores?.[athleteIdx]) { return; }
    liveScoreHeat.scores[athleteIdx].scores = liveScoreHeat.scores[athleteIdx].scores ?? [];
    liveScoreHeat.scores[athleteIdx].scores.push(null);

    const newEvent = cloneDeep(event);
    setEvent(newEvent);
  }

  const removeLiveScoreForAthlete = (athleteIdx, scoreIdx) => {
    const liveScoreHeat = getLiveScoreHeat();

    if (!liveScoreHeat.scores?.[athleteIdx]?.scores?.[scoreIdx]) { return; }
    liveScoreHeat.scores[athleteIdx].scores.splice(scoreIdx, 1);

    liveScoreHeat.scores[athleteIdx] = updateAthleteTopScores(liveScoreHeat.scores[athleteIdx])

    const newEvent = cloneDeep(event);
    setEvent(newEvent);
  }

  const getTopScores = (athleteHeat) => {
    // console.log(athleteHeat);
    const originalScores = athleteHeat.scores
      .map((score) => parseFloat(score))
      .map((score) => isNaN(score) ? 0 : score)
      .sort((a, b) => b - a);
    let newScores = [...originalScores];

    const { interference1, interference2 } = athleteHeat;
    
    if (interference1 === "P1" || interference2 === "P1") {
      const secondHighest = newScores[1];
      if (secondHighest) {
        newScores[1] = secondHighest / 2;
      }
      newScores = newScores.sort((a, b) => b - a);
    }

    if (interference1 === "P2" || interference2 === "P2") {
      const secondHighest = newScores[1];
      if (secondHighest) {
        newScores[1] = 0;
      }
      newScores = newScores.sort((a, b) => b - a);
    }

    if (interference1 === "P3" || interference2 === "P3") {
      const highest = newScores[0];
      if (highest) {
        newScores[0] = 0;
      }
      newScores = newScores.sort((a, b) => b - a);
    }

    if (interference1 === "D" || interference2 === "D") {
      newScores = newScores.map(() => 0);
    }

    const topScores = newScores.slice(0, 2);
    return topScores;
  }

  const updateAthleteTopScores = (athleteHeat) => {
    const newAthleteHeat = cloneDeep(athleteHeat);

    // get the 2 highest scores from the scores array
    const [score1, score2] = getTopScores(newAthleteHeat);
    newAthleteHeat.score1 = score1;
    newAthleteHeat.score2 = score2;

    return newAthleteHeat;
  }

  const updateLiveScoreForAthlete = (athleteIdx, scoreIdx, e) => {
    const liveScoreHeat = getLiveScoreHeat();
    
    if (!liveScoreHeat.scores?.[athleteIdx]?.scores) { return; }
    liveScoreHeat.scores[athleteIdx].scores[scoreIdx] = parseFloat(e.target.value);

    liveScoreHeat.scores[athleteIdx] = updateAthleteTopScores(liveScoreHeat.scores[athleteIdx]);

    const newEvent = cloneDeep(event);
    setEvent(newEvent);
  }

  const displayScore = (score1, score2) => {
    let parsedScore1 = parseFloat(score1);
    let parsedScore2 = parseFloat(score2);
    parsedScore1 = isNaN(parsedScore1) ? 0 : parsedScore1;
    parsedScore2 = isNaN(parsedScore2) ? 0 : parsedScore2;
    const sum = parsedScore1 + parsedScore2;
    return sum === 0 ? "" : sum.toFixed(2);
  };

  const isHeatWinner = (heatScores, scoreIdx) => {
    const scores = heatScores.map((as) => Number(as.score1 ?? 0) + Number(as.score2 ?? 0));
    const maxScore = Math.max(...scores);
    if (maxScore === 0) { return false; }
    return scores[parseInt(`${scoreIdx}`)] === maxScore;
  }

  const title = globalEvent?.name ?? "No Event Selected.";

  const disabledForm = event?.rounds?.[gender]?.[roundIndex]?.completed;

  return event && globalEvent ? (
    <main className="container-main">
      <div className="row mb-2">
        <div className="col-md-8">
          <h4><small className="text-muted pe-2">Event #{eventNumber}</small> {title}</h4>
      
          { typeof liveScoreHeatIdx !== 'number' ? (
          <Nav
            className="my-4"
            variant="tabs"
          >
            <NavLink className="nav-link" end to="/dashboard/scoring/male">
              Men's Heats
            </NavLink>

            <NavLink className="nav-link" end to="/dashboard/scoring/female">
              Women's Heats
            </NavLink>
          </Nav>
          ) : <></>}
        </div>

        <div className="col-md-2">
          <div className="d-flex justify-content-end">
            <Form.Group controlId="event-status">
              <Form.Select
                value={event.status}
                onChange={(e) => {
                  const newEvent = cloneDeep(event);
                  newEvent.status = e.target.value;
                  setEvent(newEvent);
                }}
              >
                <option>Event Status</option>
                <option value="upcoming">Upcoming</option>
                <option value="running">Running</option>
                <option value="onHold">On Hold</option>
                <option value="completed">Completed</option>
                <option value="cancelled">Cancelled</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>
      </div>

      { typeof liveScoreHeatIdx !== 'number' ? (
      <div className="row mb-2">
        <div className="col">
          <h4>
            <button
              className="btn btn-link"
              onClick={() => handleRoundChange(-1)}
            >
              <i className="bi bi-chevron-left"></i>
            </button>
            <span
              className="mx-3 d-inline-block"
              style={{ width: 300, textAlign: "center" }}
            >
              {event?.rounds?.[gender]?.[roundIndex]?.name}
            </span>
            <button
              className="btn btn-link"
              onClick={() => handleRoundChange(1)}
            >
              <i className="bi bi-chevron-right"></i>
            </button>
          </h4>
        </div>
      </div>
      ) : <></>}

      <div className="row mb-4">
        <div className="col-md-10">
          <Form noValidate>
            <div className="row mb-3">
              <div className="col">
                <div className="table-container">
                  {event?.rounds[gender]?.[roundIndex] ? (
                    liveScoreHeatIdx !== null
                    ? <div>
                      <div className="table-section-header border p-3 d-flex justify-content-between">
                        <h4 className="mb-0">Heat {liveScoreHeatIdx + 1}</h4>
                        <div>
                          <Button variant="primary" onClick={() => exitLiveScoring()}>Exit live scoring</Button>
                        </div>
                      </div>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Athlete</th>
                            <th scope="col">Scores</th>
                            <th scope="col">Top Scores</th>
                            <th scope="col">Heat Total</th>
                            <th scope="col">Interference 1</th>
                            <th scope="col">Interference 2</th>
                            <th scope="col">No&nbsp;Surf</th>
                            <th scope="col">Injury</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getLiveScoreHeat()?.scores?.map((athleteScore, scoreIdx) => (
                            <tr key={"live-score" + scoreIdx}>
                              <td> {/* Athlete name */}
                                <Form.Group controlId={`athletescore-${scoreIdx}`}>
                                  <Typeahead
                                    disabled={disabledForm}
                                    id={"athlete-" + scoreIdx}
                                    labelKey={(athlete) =>
                                      athlete.firstName
                                        ? `${athlete.firstName} ${athlete.lastName}`
                                        : ""
                                    }
                                    onChange={(selections) => handleLiveAthleteChange(scoreIdx, selections)}
                                    options={Object.values(athletes)}
                                    placeholder="Select an athlete"
                                    defaultSelected={[athleteScore.athlete]}
                                    isInvalid={
                                      !athleteScore.athlete?.firstName &&
                                      !athleteScore.athlete?.new
                                    }
                                  />
                                </Form.Group>
                              </td>

                              <td> {/* Live scores */}
                                {athleteScore.scores.map((score, athleteScoreIdx) => (
                                <div key={`score-input-${scoreIdx}-${athleteScoreIdx}`} className="d-flex mb-2">
                                  <Form.Group controlId={`score-${scoreIdx}-${athleteScoreIdx}`}>
                                    <Form.Control
                                      disabled={disabledForm}
                                      type="text"
                                      placeholder={`Score ${athleteScoreIdx + 1}`}
                                      defaultValue={score}
                                      onChange={(e) => updateLiveScoreForAthlete(scoreIdx, athleteScoreIdx, e)}
                                    />
                                  </Form.Group>
                                  <Button variant="secondary" onClick={() => removeLiveScoreForAthlete(scoreIdx, athleteScoreIdx)}>
                                    <i className="bi bi-x-lg"></i>
                                  </Button>
                                </div>
                                ))}
                                <Button variant="primary" onClick={() => addLiveScoreForAthlete(scoreIdx)}>
                                  <i className="bi bi-plus-lg"></i> Add score
                                </Button>
                              </td>

                              <td> {/* Top scores */}
                                <span className="top-score me-2">{athleteScore.score1 ?? '-'},</span>
                                <span className="top-score me-2">{athleteScore.score2 ?? '-'}</span>
                              </td>

                              <td> {/* Heat Total */}
                                <strong className="text-primary">
                                  {displayScore(athleteScore.score1, athleteScore.score2)}
                                </strong>
                              </td>

                              <td>
                                <Form.Group
                                  controlId={"interference-1-" + scoreIdx}
                                >
                                  <Form.Select
                                    tabIndex={-1}
                                    disabled={disabledForm}
                                    defaultValue={athleteScore.interference1}
                                    onChange={(e) => {
                                      const newEvent = cloneDeep(event);
                                      newEvent.rounds[gender][roundIndex].heats[
                                        liveScoreHeatIdx
                                      ].scores[scoreIdx].interference1 =
                                        e.target.value;
                                      newEvent.rounds[gender][roundIndex].heats[liveScoreHeatIdx].scores[scoreIdx]
                                        = updateAthleteTopScores(newEvent.rounds[gender][roundIndex].heats[liveScoreHeatIdx].scores[scoreIdx]);

                                      setEvent(newEvent);
                                    }}
                                  >
                                    <option>Interference 1</option>
                                    <option value="P1">Penalty 1</option>
                                    <option value="P2">Penalty 2</option>
                                    <option value="P3">Penalty 3</option>
                                    <option value="D">Disqualified</option>
                                  </Form.Select>
                                </Form.Group>
                              </td>
                              <td>
                                <Form.Group
                                  controlId={"interference-2-" + scoreIdx}
                                >
                                  <Form.Select
                                    tabIndex={-1}
                                    disabled={disabledForm}
                                    defaultValue={athleteScore.interference2}
                                    onChange={(e) => {
                                      const newEvent = cloneDeep(event);
                                      newEvent.rounds[gender][roundIndex].heats[
                                        liveScoreHeatIdx
                                      ].scores[scoreIdx].interference2 =
                                        e.target.value;
                                      setEvent(newEvent);
                                    }}
                                  >
                                    <option>Interference 2</option>
                                    <option value="P1">Penalty 1</option>
                                    <option value="P2">Penalty 2</option>
                                    <option value="P3">Penalty 3</option>
                                    <option value="D">Disqualified</option>
                                  </Form.Select>
                                </Form.Group>
                              </td>
                              <td>
                                <Form.Check
                                  tabIndex={-1}
                                  disabled={disabledForm}
                                  type="checkbox"
                                  id={"no-surf-" + scoreIdx}
                                  defaultChecked={athleteScore.noSurf}
                                  onChange={(e) => {
                                    const newEvent = cloneDeep(event);
                                    newEvent.rounds[gender][roundIndex].heats[
                                      liveScoreHeatIdx
                                    ].scores[scoreIdx].noSurf =
                                      e.target.checked;
                                    setEvent(newEvent);
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Check
                                  tabIndex={-1}
                                  disabled={disabledForm}
                                  type="checkbox"
                                  id={"injury-" + scoreIdx}
                                  defaultChecked={athleteScore.injury}
                                  onChange={(e) => {
                                    const newEvent = cloneDeep(event);
                                    newEvent.rounds[gender][roundIndex].heats[
                                      liveScoreHeatIdx
                                    ].scores[scoreIdx].injury =
                                      e.target.checked;
                                    setEvent(newEvent);
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    : (
                      event.rounds[gender]?.[roundIndex].heats?.map((heat, heatIdx) => (
                        <div key={`${event.id}-${gender}-heat-${heatIdx}-round-${roundIndex}`} >
                          <div className="table-section-header border p-3 d-flex justify-content-between">
                            <h4 className="mb-0">Heat {heatIdx + 1} <small className="text-muted"> of {event.rounds[gender]?.[roundIndex].heats?.length ?? 0}</small></h4>
                            <div>
                              <Button variant="primary" onClick={() => toggleLiveScoring(heatIdx)}>Live scoring</Button>
                            </div>
                          </div>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Athlete</th>
                                <th scope="col">Score 1</th>
                                <th scope="col">Score 2</th>
                                <th scope="col">Heat Total</th>
                                <th scope="col">Interference 1</th>
                                <th scope="col">Interference 2</th>
                                <th scope="col">No&nbsp;Surf</th>
                                <th scope="col">Injury</th>
                              </tr>
                            </thead>
                            <tbody>
                              {heat?.scores?.map((athleteScore, scoreIdx) => (
                                <tr key={"score" + scoreIdx} className={isHeatWinner(heat.scores, scoreIdx) ? 'heat-winner' : ''}>
                                  <td>
                                    <Form.Group controlId={"athlete-" + scoreIdx}>
                                      <Typeahead
                                        disabled={disabledForm}
                                        id={"athlete-" + scoreIdx}
                                        labelKey={(athlete) =>
                                          athlete.firstName
                                            ? `${athlete.firstName} ${athlete.lastName}`
                                            : ""
                                        }
                                        onChange={(selections) => {
                                          const newEvent = cloneDeep(event);
                                          newEvent.rounds[gender][roundIndex].heats[
                                            heatIdx
                                          ].scores[scoreIdx].athlete =
                                            selections[0] ?? {};
                                          delete newEvent.rounds[gender][roundIndex]
                                            .heats[heatIdx].scores[scoreIdx]
                                            .athlete.new;
                                          setEvent(newEvent);
                                        }}
                                        options={Object.values(athletes)}
                                        placeholder="Select an athlete"
                                        defaultSelected={[athleteScore.athlete]}
                                        isInvalid={
                                          !athleteScore.athlete?.firstName &&
                                          !athleteScore.athlete?.new
                                        }
                                      />
                                    </Form.Group>
                                  </td>
                                  <td>
                                    <Form.Group controlId={"score-1-" + scoreIdx}>
                                      <Form.Control
                                        disabled={disabledForm}
                                        type="text"
                                        placeholder="Score 1"
                                        defaultValue={athleteScore.score1}
                                        onChange={(e) => {
                                          const newEvent = cloneDeep(event);
                                          newEvent.rounds[gender][roundIndex].heats[
                                            heatIdx
                                          ].scores[scoreIdx].score1 =
                                            e.target.value;
                                          setEvent(newEvent);
                                        }}
                                      />
                                    </Form.Group>
                                  </td>
                                  <td>
                                    <Form.Group controlId={"score-2-" + scoreIdx}>
                                      <Form.Control
                                        disabled={disabledForm}
                                        type="text"
                                        placeholder="Score 2"
                                        defaultValue={athleteScore.score2}
                                        onChange={(e) => {
                                          const newEvent = cloneDeep(event);
                                          newEvent.rounds[gender][roundIndex].heats[
                                            heatIdx
                                          ].scores[scoreIdx].score2 =
                                            e.target.value;
                                          setEvent(newEvent);
                                        }}
                                      />
                                    </Form.Group>
                                  </td>
                                  <td>
                                    <Form.Group controlId={"heat-total-" + scoreIdx}>
                                      <Form.Control
                                        disabled={disabledForm}
                                        type="text"
                                        className="text-primary"
                                        style={{ border: 'none', backgroundColor: 'transparent' }}
                                        placeholder="Heat Total"
                                        defaultValue={displayScore(athleteScore.score1, athleteScore.score2)}
                                        onChange={(e) => {
                                          const newEvent = cloneDeep(event);
                                          let halfScore = parseFloat(e.target.value) / 2;
                                          halfScore = isNaN(halfScore) ? null : halfScore;
                                          const scores = newEvent.rounds[gender][roundIndex].heats[heatIdx].scores[scoreIdx];
                                          scores.score1 = halfScore;
                                          scores.score2 = halfScore;
                                          setEvent(newEvent);
                                        }}
                                      />
                                    </Form.Group>
                                  </td>
                                  <td>
                                    <Form.Group
                                      controlId={"interference-1-" + scoreIdx}
                                    >
                                      <Form.Select
                                        tabIndex={-1}
                                        disabled={disabledForm}
                                        defaultValue={athleteScore.interference1}
                                        onChange={(e) => {
                                          const newEvent = cloneDeep(event);
                                          newEvent.rounds[gender][roundIndex].heats[
                                            heatIdx
                                          ].scores[scoreIdx].interference1 =
                                            e.target.value;
                                          setEvent(newEvent);
                                        }}
                                      >
                                        <option>Interference 1</option>
                                        <option value="P1">Penalty 1</option>
                                        <option value="P2">Penalty 2</option>
                                        <option value="P3">Penalty 3</option>
                                        <option value="D">Disqualified</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </td>
                                  <td>
                                    <Form.Group
                                      controlId={"interference-2-" + scoreIdx}
                                    >
                                      <Form.Select
                                        tabIndex={-1}
                                        disabled={disabledForm}
                                        defaultValue={athleteScore.interference2}
                                        onChange={(e) => {
                                          const newEvent = cloneDeep(event);
                                          newEvent.rounds[gender][roundIndex].heats[
                                            heatIdx
                                          ].scores[scoreIdx].interference2 =
                                            e.target.value;
                                          setEvent(newEvent);
                                        }}
                                      >
                                        <option>Interference 2</option>
                                        <option value="P1">Penalty 1</option>
                                        <option value="P2">Penalty 2</option>
                                        <option value="P3">Penalty 3</option>
                                        <option value="D">Disqualified</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </td>
                                  <td>
                                    <Form.Check
                                      tabIndex={-1}
                                      disabled={disabledForm}
                                      type="checkbox"
                                      id={"no-surf-" + scoreIdx}
                                      defaultChecked={athleteScore.noSurf}
                                      onChange={(e) => {
                                        const newEvent = cloneDeep(event);
                                        newEvent.rounds[gender][roundIndex].heats[
                                          heatIdx
                                        ].scores[scoreIdx].noSurf =
                                          e.target.checked;
                                        setEvent(newEvent);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Check
                                      tabIndex={-1}
                                      disabled={disabledForm}
                                      type="checkbox"
                                      id={"injury-" + scoreIdx}
                                      defaultChecked={athleteScore.injury}
                                      onChange={(e) => {
                                        const newEvent = cloneDeep(event);
                                        newEvent.rounds[gender][roundIndex].heats[
                                          heatIdx
                                        ].scores[scoreIdx].injury =
                                          e.target.checked;
                                        setEvent(newEvent);
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ))
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>

      <div className="bottom-bar">
        <div className="row">
          <div className="col-md-10 d-flex align-items-center justify-content-end">
            <Spinner
              animation="border"
              className="mx-2"
              role="status"
              hidden={!isLoading}
            />
            <Button
              variant="primary"
              className="mx-2"
              disabled={isLoading}
              onClick={handleSave}
            >
              Save and Update Scores
            </Button>

            {!event.rounds[gender][roundIndex]?.completed
            ? (
              <Button
                variant="warning"
                className="mx-2"
                disabled={isLoading}
                onClick={handleEndRound}
              >
                End Round
              </Button>
            )
            : (
              <Button
                variant="secondary"
                className="mx-2"
                disabled={isLoading}
                onClick={handleReopenRound}
              >
                Re-Open this round
              </Button>
            ) 
            }
          </div>
        </div>
      </div>
    </main>
  ) : (
    <div className="error">Please select an active event.</div>
  );
}

export default Scores;
