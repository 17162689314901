import { useContext, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { titleCase } from "../../app/helpers/string.helpers";
import PickerListItem from './PickerListItem';

import './entity-list.css';
import { Form } from "react-bootstrap";
import { Timestamp } from "firebase/firestore";
import { SeasonContext } from "../../contexts/SeasonsContext";

export default function EntityListScreen({
  title,
  entityNameSingular,
  entityNamePlural,
  showIndices,
  fields = [{ fieldName: 'Name', value: 'name' }],
  filters,
  actions,
  editScreenName,
  getItems,
  deleteItem,
}) {
  // General state
  const [entities, setEntities] = useState([]);
  const [filteredEntities, setFilteredEntities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [resolvedFilters, setResolvedFilters] = useState({});
  const defaultSortingField = fields?.find((field) => field.isDefaultSorting);
  const defaultSorting = defaultSortingField ? { [defaultSortingField.fieldName]: defaultSortingField.defaultSortingDirection ?? 'asc' } : { name: 'asc' };
  const [sorting, setSorting] = useState(defaultSorting);

  const { season, setSeason } = useContext(SeasonContext);

  const navigate = useNavigate();

  useEffect(() => {
    loadEntities(season);
  }, [season]);

  useEffect(() => {
    if (!filters) { return; } // If filters is falsy, exit early.
  
    const asyncOptionsPromises = [];
  
    // Loop over each filter in the filters object.
    for (const [key, filter] of Object.entries(filters)) {
      // If the filter has an async options function, add a promise to the array.
      if (filter.optionsAsync && (typeof filter.optionsAsync.promise === 'function')) {
        asyncOptionsPromises.push(
          // Call the async options function and update options if a map function is provided.
          filter.optionsAsync.promise().then(async (options) => {
            if (typeof filter.optionsAsync.map === 'function') {
              const newOptions = await Promise.all(options.map(filter.optionsAsync.map));
              return { [key]: { ...filter, options: newOptions } };
            } else {
              // If there is no map function, simply update the options.
              return { [key]: { ...filter, options } };
            }
          })
          // Log an error if the async options function fails and return the original filter object.
          .catch((error) => {
            console.error(`Error fetching options for filter '${key}':`, error);
            return { [key]: filter };
          })
        );
      } else {
        // If the filter does not have an async options function, add a resolved filter object to the promise array.
        asyncOptionsPromises.push(Promise.resolve({ [key]: filter }));
      }
    }
  
    // Wait for all the promises to resolve and then update the resolvedFilters state with the new filter options.
    Promise.all(asyncOptionsPromises).then((resolvedFiltersArray) => {
      const resolvedFiltersObject = Object.assign({}, ...resolvedFiltersArray);
      setResolvedFilters(prevResolvedFilters => ({ ...prevResolvedFilters, ...resolvedFiltersObject }));
    }).catch((error) => {
      console.error('Error resolving options promises:', error);
    });
  
  }, [filters]);

  useEffect(() => filterEntities(), [entities, selectedFilters, sorting]);

  /** Loads the entities from the API. If empty, shows a message that says there are no entities. */
  const loadEntities = (season) => {
    getItems?.(season)
      .then((data) => {
        const entities = data && data.length > 0 ? data : [{ name: `No ${entityNamePlural ?? entityNameSingular + 's'} yet.` }];
        for (const entity of entities) {
          for (const [prop, value] of Object.entries(entity)) {
            if (value instanceof Timestamp) { entity[prop] = value.toDate(); }
          }
        }
        setEntities(entities);
        setSelectedFilters({});
        setLoading(false);
      })
      .catch((error) => {
        console.error(error)
        setEntities([{ name: error.message ?? error }]);
        setFilteredEntities([{ name: error.message ?? error }]);
        setLoading(false);
      });
  };

  const filterEntities = (allEntities) => {
    // If allEntities is not passed in, default to using the current entities state
    allEntities = allEntities ?? entities;
  
    const filteredEntities = allEntities
      // Filter entities based on selectedFilters and their associated filter functions
      .filter((entity) => {
        let passed = true;
        for (const [key, value] of Object.entries(selectedFilters)) {
          passed = !value || (passed && filters[key]?.filterFn?.(entity, value));
        }
        return passed;
      })
      // Sort entities based on sorting state
      .sort((a, b) => {
        const [sortKey, sortDir] = Object.entries(sorting ?? {})[0] ?? []; // Get the first key/value pair in the sorting object
        if (!sortKey) { return 0; }
        // Find the field object associated with the sort key
        const field = fields.find((field) => field.fieldName.toLowerCase() === sortKey.toLowerCase());
        if (!field) { return 0; }
        // If the field has a custom sort function, use it
        if (typeof field.sort === 'function') {
          return sortDir === 'asc' ? field.sort(a, b) : field.sort(b, a);
        // If the field has a custom value function, use it to get the value to sort on
        } else if (typeof field.value === 'function') {
          return sortDir === 'asc' ? `${field.value(a)}`.localeCompare(`${field.value(b)}`) : `${field.value(b)}`.localeCompare(`${field.value(a)}`);
        // Otherwise, use the field value as the key to sort on
        } else {
          return sortDir === 'asc' ? `${a[field.value]}`.localeCompare(`${b[field.value]}`) : `${b[field.value]}`.localeCompare(`${a[field.value]}`);
        }
      });
  
    // Update the filtered entities state with the new filtered and sorted entities
    setFilteredEntities(filteredEntities);
  }

  const changeSorting = (key) => {
    key = key.toLowerCase();
    if (Object.keys(sorting)[0]?.toLowerCase() === key) {
      setSorting({ [key]: sorting[key] === 'asc' ? 'desc' : 'asc' });
    } else {
      setSorting({ [key]: 'asc' });
    }
  }

  /** Opens the screen for editing an entity */
  const editEntity = (entity) => {
    if (!editScreenName) { return console.warn('No edit screen for ' + entityNameSingular); }
    if(entity?.id) {
      navigate(`${editScreenName}/${entity.id}`);
    } else {
      navigate(`${editScreenName}`);
    }
  };

  const deleteEntity = (entity) => {
    const deleteConfirm = confirm(`Delete ${entityNameSingular}
Are you sure you want to delete this ${entityNameSingular}?`);

    if (!deleteConfirm) { return; }

    setDeleting(entity.id ?? entity.name);
    deleteItem?.(entity)?.then(() => {
      loadEntities();
      setDeleting(undefined);
    });
  }

  const loadingTpl = (
    <div className="text-center">
      Loading...
    </div>
  );

  const listTpl = (
    <table className="entity-table table table-responsive">
      <thead>
        <tr>
          {showIndices && <th>#</th>}
          {fields?.map(({fieldName, sort}, idx) => (
          <th key={idx}>
            {sort === false && <span>{fieldName}</span> }
            {sort !== false &&
              <a href="#" className="text-dark" onClick={(e) => { e.preventDefault(); changeSorting(fieldName); }}>
                {fieldName}
                {Object.keys(sorting)[0]?.toLowerCase() === fieldName.toLowerCase() && (<i className={'ms-1 bi ' + ((Object.values(sorting)[0] === 'asc') ? 'bi-arrow-up' : 'bi-arrow-down')}></i>)}
              </a>
            }
          </th>
          ))}

          <th id="actions"></th>
        </tr>
      </thead>

      <tbody>
      {filteredEntities?.map((item, idx) => (
        <PickerListItem
          item={item}
          index={showIndices ? idx + 1 : undefined}
          fields={fields}
          actions={actions?.map((action) => {
            action.callback = (entity) => {
              const newEntities = [...entities];
              const index = newEntities.findIndex((e) => e.id === entity?.id);
              if (index) {
                newEntities[index] = entity;
              }
              setEntities(newEntities);
            };
            return action;
          })}
          key={item?.id ?? item?.name ?? idx}
          onPress={item?.id ? () => editEntity(item) : undefined}
          onDelete={item?.id ? () => deleteEntity(item) : undefined}
          disabled={deleting ? (deleting === (item.id ? item.id : item.name)) : false}
        />
      ))}
      </tbody>
    </table>
  );

  return (
    <div className={'entity-list ' + entityNamePlural}>
      <Helmet>
      <title>{title ?? titleCase(entityNamePlural)}</title>
      </Helmet>

      <main className="page-header container-fluid container-main">
        <div className="row mb-5">
          <div className="col-12">
            <h1>{title ?? titleCase(entityNamePlural)}</h1>
          </div>
          <div className="col-10 row">
            { filters && Object.entries(resolvedFilters).map(([key, filter]) => (
              <div className="col-md-3" key={'filter-' + key}>
                <Form.Select
                  className="form-control"
                  value={selectedFilters[key] ?? ''}
                  onChange={(e) => {
                    setSelectedFilters({ ...selectedFilters, [key]: e.target.value });
                  }}
                >
                  <option value="">Filter by {key}</option>
                  { filter.options?.map((option) => (
                    <option key={'filter-option-' + key + '-' + option.value} value={option.value}>{option.label}</option>
                  )) }
                </Form.Select>
              </div>
            )) }

            {filters && 
              <div className="col-md-2">
              <button className="btn btn-primary" onClick={() => setSelectedFilters({})}>
                Clear Filters
              </button>
              </div>}
          </div>
          <div className="col-2">
            <button className="btn btn-primary" onClick={() => editEntity()}>
              New {entityNameSingular}
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col">
            {loading ? loadingTpl : listTpl}
          </div>
        </div>

      </main>
    </div>
  );
}
