import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { dbStore } from "../../services/api/db-store.service";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormLabel,
  FormSelect,
  Row,
  Spinner,
} from "react-bootstrap";
import { EventContext, EventsContext } from "../../contexts/EventContext";
import { CountriesContext } from "../../contexts/CountriesContext";
import DatePicker from 'react-datepicker';
import { Timestamp } from "firebase/firestore";

function Event() {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [originalName, setOriginalName] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { countries } = useContext(CountriesContext);
  const { setEvents } = useContext(EventsContext);

  const { event: globalEvent, setEvent: setGlobalEvent } = useContext(EventContext);

  useEffect(() => {
    setIsLoading(true);
    // load event from api if id is present
    if (id) {
      dbStore.getDocument("events", id).then((data) => {
        if (!data) {
          navigate("/error-404");
        } else {
          data.startDate = data.startDate instanceof Timestamp ? data.startDate.toDate() : null; // convert Firestore timestamp to date
          data.endDate = data.endDate instanceof Timestamp ? data.endDate.toDate() : null; // convert Firestore timestamp to date
          setEvent(data);
          setOriginalName(data.name);
          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(false);
      setEvent({});
    }
  }, [id]);

  //form field validators
  const validators = {
    name: {
      required: {
        value: true,
        message: "Name is required",
      },
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validators) {
      let valid = true;
      const errs = {};
      for (const fieldname of Object.keys(validators)) {
        const value = event[fieldname];
        const validator = validators[fieldname];
        if (validator?.required?.value && !value) {
          valid = false;
          errs[fieldname] = validator.required.message;
        }

        const pattern = validator?.pattern;
        if (pattern?.value && !RegExp(pattern.value).test(value)) {
          valid = false;
          errs[fieldname] = pattern.message;
        }

        const custom = validator?.custom;
        if (custom?.isValid && !custom.isValid(value)) {
          valid = false;
          errs[fieldname] = custom.message;
        }
      }

      if (!valid) {
        setErrors(errs);
        return;
      }
    }

    setErrors({});

    //if no errors
    handleSave(e, event);
  };

  const handleSave = async () => {
    setIsLoading(true);
    const data = {
      name: event.name,
      shortCode: event.shortCode,
      startDate: event.startDate,
      endDate: event.endDate,
      eventNumber: event.eventNumber,
      status: event.status,
      country: event.country,
    };
    // strip undefined values
    Object.keys(data).forEach((key) => data[key] === undefined && delete data[key]);

    if (id) {
      await dbStore.updateDoc("events", id, data);
    } else {
      await dbStore.addDoc("events", data);
    }

    // update events in context
    const events = await dbStore.getDocumentsDict("events");
    setEvents(events);

    setIsLoading(false);
    navigate("/dashboard/events");
  };

  const title = isLoading ? "Loading" : originalName ?? "New Event";

  const FormErrors = () => (
    <div>
      {Object.keys(errors).map((key) => (
        <div className="text-danger" key={key}>
          {errors[key]}
        </div>
      ))}
    </div>
  );

  const SubmitButton = () => (
    <Button
      className="btn btn-lg btn-primary"
      type="button"
      disabled={isLoading}
      onClick={handleSubmit}
    >
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        hidden={!isLoading}
      />
      <span className="px-2">Save</span>
    </Button>
  );

  // return event === 'error'
  //   ? <div>This event does not exist.</div>
  //   : event
  //     ? <div>Behold, {event.name ?? 'a event!'}</div>
  //     : <div className="loading">Loading...</div>

  return (
    <main className="container-main">
      <div className="row mb-4">
        <div className="col">
          <Link to="/dashboard/events">{"<"} Back to Events</Link>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <h1>{title}</h1>
          {event && <Link onClick={() => { setGlobalEvent(event)}} to="/dashboard/scoring">Go to {event.status === 'completed' ? 'Results' : 'Scoring'}</Link>}
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-10">
          {!event ? (
            <div className="loading">Loading...</div>
          ) : (
            <Form noValidate>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="form-floating mb-3" controlId="name">
                    <FormControl
                      type="text"
                      className="form-control form-input-top"
                      isInvalid={errors?.name}
                      placeholder="Name"
                      value={event.name}
                      onChange={(e) =>
                        setEvent({ ...event, name: e.target.value })
                      }
                    />
                    <FormLabel>Name</FormLabel>
                  </Form.Group>
                  <FormErrors />
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group
                        className="form-floating mb-3"
                        controlId="shortCode"
                      >
                        <FormControl
                          type="text"
                          className="form-control form-input-top"
                          isInvalid={errors?.shortCode}
                          placeholder="Short Name"
                          value={event.shortCode}
                          onChange={(e) =>
                            setEvent({ ...event, shortCode: e.target.value })
                          }
                        />
                        <FormLabel>Short Code</FormLabel>
                      </Form.Group>
                      <FormErrors />
                    </div>
                    <div className="col-md-6">
                      <Form.Group controlId="country">
                        <FormSelect
                          className="form-control form-input-top"
                          isInvalid={errors?.country?.invalid}
                          value={event.country}
                          onChange={(e) =>
                            setEvent({ ...event, country: e.target.value })
                          }
                        >
                          <option value="">Select a Country</option>
                          {countries.map((country) => (
                            <option key={country.name} value={country.tricode}>
                              {country.name}
                            </option>
                          ))}
                        </FormSelect>
                      </Form.Group>

                      <FormErrors />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="date-picker">
                        <label htmlFor="startDate">Event start date</label>
                        <DatePicker id="startDate" className={'form-control py-3' + (errors?.startDate?.invalid ? ' is-invalid' : '')} selected={event.startDate ? new Date(event.startDate) : null} onChange={(date) => setEvent({ ...event, startDate: date })} />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="date-picker">
                        <label htmlFor="endDate">Event end date</label>
                        <DatePicker id="endDate" className={'form-control py-3' + (errors?.endDate?.invalid ? ' is-invalid' : '')} selected={event.endDate ? new Date(event.endDate) : null} onChange={(date) => setEvent({ ...event, endDate: date })} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group
                        className="form-floating mb-3"
                        controlId="eventNumber"
                      >
                        <FormControl
                          type="number"
                          className="form-control form-input-top"
                          isInvalid={errors?.eventNumber}
                          placeholder="Event Number"
                          value={event.eventNumber}
                          onChange={(e) =>
                            setEvent({ ...event, eventNumber: Number(e.target.value) })
                          }
                        />
                        <FormLabel>Event Number</FormLabel>
                      </Form.Group>
                      <FormErrors />
                    </div>

                    <div className="col-md-6">
                      <Form.Group controlId="status">
                        <FormSelect
                          className="form-control mb-3"
                          value={event.status}
                          onChange={(e) =>
                            setEvent({ ...event, status: e.target.value })
                          }
                        >
                          <option value="">Status</option>
                          <option value="upcoming">Upcoming</option>
                          <option value="running">Running</option>
                          <option value="on hold">On Hold</option>
                          <option value="completed">Completed</option>
                          <option value="cancelled">Cancelled</option>
                        </FormSelect>
                      </Form.Group>
                      <FormErrors />
                    </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
              </div>
            </Form>
          )}
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 col-md-8">
          <SubmitButton />
        </div>
      </div>
    </main>
  );
}

export default Event;
