export function titleCase(str) {
  return str
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
}

export function getCardinalSuffix(num) {
  const j = num % 10;
  const k = num % 100;

  if (j === 1 && k !== 11) {
    return 'st';
  }

  if (j === 2 && k !== 12) {
    return 'nd';
  }

  if (j === 3 && k !== 13) {
    return 'rd';
  }

  return 'th';
}